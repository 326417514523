<template>
    <div class = "navBar">
      <input type = "checkbox" id = "navCheckbox" v-model = "checkBoxNav">                
        <div id = "siteName"><span title = "App Home Page" @click = "viewCalculator(); checkBoxNav = false">What I Cost</span></div>
        <div id = "sitePhrase">{{siteMessage}}</div>
        <div id = "siteNav">
          <div class="navButton">
            <label for="navCheckbox">
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>
          <div class = "navDirects">          
                  <span @click = "viewAbout(); checkBoxNav = false">About</span>
                  <span @click = "viewArticles(); checkBoxNav = false">Articles</span>
                  <span @click = "viewHelp(); checkBoxNav = false">Help (?)</span>
                  <span @click = "viewCalculator(); checkBoxNav = false">App</span>         
          </div>
    </div>
</template>

<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-ZZRH17LP8W"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-ZZRH17LP8W');
</script>


<script>
export default {
    name: 'TitleHeader',
    props: {userInfoObj: Object},
    data(){
        return{ 
            phrases: ["Forecasting Life Finance"],
            pageIDs: { 
                helpPage: "helpPage",
                aboutPage: "aboutPage",
                calculatorPage: "calcPage",
                articlesPage: "artPage"
            },
            navOn: false,
            checkBoxNav: false
            //"Capital Forecasting - Budgeting Inverted", "Better Budget Building", "Budget By You"
        }
    },
    methods: {
        viewCalculator(){
          this.$emit('calcOn', this.pageIDs.calculatorPage)
          console.log("TURNING CALCULATOR PAGE ON");

          this.$gtag.event('Site-Title-Clicked', {
        'event_category': 'T-I-E',
        'event_label': 'Website Title Clicked',
        'value': 1
      })
        },
        viewAbout(){
          this.$emit('aboutOn', this.pageIDs.aboutPage);
          console.log("TURNING ABOUT PAGE ON");

          this.$gtag.event('About-Page-Clicked', {
        'event_category': 'A-P-C',
        'event_label': 'About Page Viewed',
        'value': 1
      })
        },
        viewHelp(){
          this.$emit('helpOn', this.pageIDs.helpPage);
          console.log("TURNING HELP PAGE ON");

          this.$gtag.event('Help-Page-Clicked', {
        'event_category': 'H-P-C',
        'event_label': 'Help Page Viewed',
        'value': 1
      })
        },
        viewArticles(){ 
          this.$emit('articlesOn', this.pageIDs.articlesPage);
          console.log("TURNING ARTICLES ON");

          this.$gtag.event('Articles-Clicked', {
        'event_category': 'A-C',
        'event_label': 'Articles Page Viewed',
        'value': 1
      })
        }
    },
    computed: {
        siteMessage(){ return this.phrases[Math.floor(Math.random()*this.phrases.length)]},
        propUIObject(){return this.userInfoObj}
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri&display=swap');    
    

.navBar{
  height: 100%;
  background: #197C17; 
  color: white;
  font-family: 'Hind Siliguri', sans-serif;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "names phrase navs";
}

#siteName{
  grid-area: names;
  color: white;
  display: inline-block;
  font-size: 26px;
  padding: 10px 10px 0px 10px;
  text-transform: bold;
  vertical-align: middle;
  margin-left: 20px
}

.navBar > .navButton {
  display: none
  }

.navDirects {
  float: right;
  vertical-align: middle;
  font-size: 22px;
}

.navDirects > span {
  display: inline-block;
  padding: 5px 23px 1px 0px;
  text-decoration: none;
  color: #eaeaea;
}

.navDirects > span:hover {
  color: #fff8f8;
  cursor: pointer;
  text-decoration: underline;
}

.navBar > #navCheckbox {
  display: none;
}

#sitePhrase{
    grid-area: phrase;
    padding: 10px 10px 13px 10px;
    /*overflow-wrap: break-word;*/
    font-size: 24px;
    width: 100%; 
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
}

#siteName > span:hover {
  text-decoration: underline;
  cursor: pointer;
}

#siteNav{
  grid-area: navs;
  padding: 6px;
  /*padding-right: 5%;*/
}

@media (max-width: 1000px) {

.navBar {
  background: #197C17; 
  color: White;
  font-family: 'Hind Siliguri', sans-serif;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-areas: "names1 phrase1 navs1";
  border-radius: 5px;
}

#siteName{
  grid-area: names1;
  color: white;
  display: inline;
  width: max-content;
  font-size: 26px;
  padding: 4px 10px 0px 10px;
  text-transform: bold;
  text-align: center;
  vertical-align: middle;
}

#siteNav{
  grid-area: navs1;
  margin: 0 auto;
  font-size: 26px;
  height: 100%;
}

#sitePhrase{
  grid-area: phrase1;
  font-size: 24px;
  padding: 10px 10px 13px 10px;
}

.navBar > #siteNav > .navButton {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 12px;
}

 .navBar >  #siteNav > .navButton > label {
  display: inline-block;
  width: 50px;
  height: 50px;
  
}

.navBar >  #siteNav > .navButton > label:hover,.nav  #navCheckbox:checked ~ .navButton > label {
  background: #000000;
}

.navBar >  #siteNav > .navButton > label > span {
  width: 25px;
  height: 10px;
  border-bottom: 2px solid #eee;
  margin: 0 auto;
}

.navBar > #siteNav > .navDirects {
  position: absolute;
  width: 100%;
  background: #197C17;
  height: 0px;
  transition: all 0.3s ease-in;
  overflow-y: hidden;
  top: 50px;
  right: 0px;
  font-size: clamp(1.2rem, 2rem, 4.2rem)
}

.navBar > #siteNav > .navDirects > span {
  width: 100%;
  padding: 2px;
}

.navBar > #navCheckbox:not(:checked) ~  #siteNav > .navDirects {
  height: 0px;
}

.navBar > #navCheckbox:checked ~  #siteNav > .navDirects {
  height: calc(100vh - 50px);
  overflow-y: auto;
}
}


/*
> lang="scss">
   
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri&display=swap');    
    

.navBar{
  height: 100%;
  background: #197C17; 
  color: white;
  font-family: 'Hind Siliguri', sans-serif;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 17% 50% 33%;
  grid-template-areas: "names phrase navs";
}

#siteName{
  grid-area: names;
  color: white;
  display: inline-block;
  font-size: 26px;
  padding: 4px 10px 0px 10px;
  text-transform: bold;
  text-align: center;
  vertical-align: middle;
}

.navBar > .navButton {
  display: none
  }

.navDirects {
  float: right;
  vertical-align: middle;
  font-size: 22px;
}

.navDirects > span {
  display: inline-block;
  padding: 5px 23px 1px 0px;
  text-decoration: none;
  color: #eaeaea;
}

.navDirects > span:hover {
  color: #fff8f8;
  cursor: pointer;
  text-decoration: underline;
}

.navBar > #navCheckbox {
  display: none;
}

#sitePhrase{
    grid-area: phrase;
     padding: 6px 10px 13px 10px;
    
    font-size: 23px;
    width: 100%; 
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
}

#siteName > span:hover {
  text-decoration: underline;
  cursor: pointer;
}

#siteNav{
  grid-area: navs;
  
}

@media (max-width: 1000px) {

.navBar {
  background: #197C17; 
  color: White;
  font-family: 'Hind Siliguri', sans-serif;
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 4fr);
  grid-template-areas: "names1  navs1";
  border-radius: 5px;
}

#siteName{
  grid-area: names1;
  color: white;
  display: inline;
  width: max-content;
  font-size: 26px;
  padding: 4px 10px 0px 10px;
  text-transform: bold;
  text-align: center;
  vertical-align: middle;
}

#siteNav{
  grid-area: navs1;
  margin: 0 auto;
  font-size: 14px;
  height: 100%;
}

.navBar > #siteNav > .navButton {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 12px;
}

 .navBar >  #siteNav > .navButton > label {
  display: inline-block;
  width: 50px;
  height: 50px;
  
}

.navBar >  #siteNav > .navButton > label:hover,.nav  #navCheckbox:checked ~ .navButton > label {
  background: #000000;
}

.navBar >  #siteNav > .navButton > label > span {
  display: block;
  width: 25px;
  height: 10px;
  border-bottom: 2px solid #eee;
  margin: 0 auto;
}

.navBar > #siteNav > .navDirects {
  position: absolute;
  display: block;
  width: 100%;
  background: #197C17;
  height: 0px;
  transition: all 0.3s ease-in;
  overflow-y: hidden;
  top: 50px;
  right: 0px;
  font-size: clamp(1.2rem, 2rem, 4.2rem)
}

.navBar > #siteNav > .navDirects > span {
  display: block;
  width: 100%;
  padding: 2px;
}

.navBar > #navCheckbox:not(:checked) ~  #siteNav > .navDirects {
  height: 0px;
}

.navBar > #navCheckbox:checked ~  #siteNav > .navDirects {
  height: calc(100vh - 50px);
  overflow-y: auto;
}
}
*/

</style>