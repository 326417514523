<!-- <template>
    <div class = "footer">
        <div id = "calcFooter" @click = "viewCalculator" title="Go to Calculators">Calculators<br><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-calculator" viewBox="0 0 16 16">
            <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
            <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
            </svg>
        </div> (10) END DIV FOR CALCULATOR FOOTER / BUTTON FOR CALCULATOR FIELD

        (12) DIV FOR RESOURCES FOOTER / BUTTON FOR RESOURCES FIELD
        <div id = "resourcesFooter" @click = "viewResources" title="Go to Resources">Resources<br><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-card-text" viewBox="0 0 16 16">
            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
            <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
            </svg> 
        </div>
    </div>
</template> -->

<!-- <script>
export default {
    name: 'FooterNav',
    data(){ 
        return{
            pageIDs: { 
                calculatorPage: "calcPage",
                resourcesPage: "resPage"
            }
        }
    },
    methods: { 
        viewCalculator(){
            this.$emit('calcOn', this.pageIDs.calculatorPage)
            console.log("TURNING CALCULATOR PAGE ON");
        },
        viewResources(){
            this.$emit('resOn', this.pageIDs.resourcesPage)
            console.log("TURNING RESOURCES PAGE ON");
    }
    }
}
</script> -->

<!-- <style>
.footer{
    color: rgb(251, 253, 255);
    grid-area: footer;    
    background: rgb(251, 253, 255);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    font-style: italic;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "calcF resourcesF";
    grid-gap: 1.5%;
    padding: 1%;
}

#calcFooter{
    grid-area: calcF;
    margin: 0 auto;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: rgb(15, 71, 43);
    border: 1px solid white;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
}

#calcFooter>svg:hover{
    fill:rgb(15, 71, 43);
}

#calcFooter:hover {
    background-color: white;
    color:rgb(15, 71, 43);
    border: 1px solid rgb(15, 71, 43);
    border-radius: 5px;
    border-bottom-left-radius: 0px;
}
  
#resourcesFooter{
  grid-area: resourcesF;
  margin: 0 auto;
  cursor: pointer;
  width: 100%;
  background-color: rgb(15, 71, 43);
  border: 1px solid white;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
}

#resourcesFooter:hover {
  background-color: white;
  color:rgb(15, 71, 43);
  border: 1px solid rgb(15, 71, 43);
  border-radius: 5px;
  border-bottom-left-radius: 0px;    
}
 <p>This service is open to public use free of charge. Use of forecaster services is an agreement on the user's behalf to accept responsibility for outcome of use. Good luck, use thoughtfully, hope for the best and prepare for the worst.</p>
</style> -->

<template>
    <div class = "containerFooter">        
        <table>
            <tr>
                <td>By Capfores LLC - 2022</td>
                <!-- <td>About Developer</td> -->
                <td>Mission: Simplify Planning Financial Balance and Security. Support us and <a href = "https://www.buymeacoffee.com/capfores"><strong>'Buy me a coffee'</strong></a></td>
            </tr>
        </table>
    </div>  
          
</template>

<script>
export default {
    name: 'FooterNav',
    data(){ 
        return{
            pageIDs: { 
                calculatorPage: "calcPage",
                resourcesPage: "resPage"
            }}}}

</script>

<style>

.containerFooter{
    background: #197C17 ;
    color: white;
    font-size: 1.2rem;
    height: auto;
    margin-bottom: 8px;
    border-radius: 5px;
    padding-bottom: 8px;
    width: 100%;
}

.container > #num2 > p {
    font: 1em;
}

td{
    width: max-content;
}

table{
    table-layout: fixed;
    width: 100%;
}

a{
    color: white
}

</style>

