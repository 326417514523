<template>
    <div class = "helpContent">
        <h1>Help / Guide</h1>
        <p>What I Cost provides expense organization and calculation services. It aims to be simple and effective at forecasting your expnses, earnings demands, and tax consequences. 
            <table>
                <tr>    
                    <td>Edit user information to reflect:</td>
                </tr>
                <tr><td></td></tr>
                <tr><td>- Your state of residence</td></tr>
                <tr><td>- Your filing status</td></tr>
                <tr><td>- Your anticipated deductions and credits</td></tr>
                <tr><td>- Your payroll deductions</td></tr>
                <tr><td>- Your local tax</td></tr>
                <tr><td>- Your current earnings (optional for purely capital demand forecasting)</td></tr>
            </table>
            <p>To estimate your capital demand, enter all your financial obligation and elections into the expense table. Think on this and really try to imagine what a fulfilled life entails for you. Hobbies, vacations, savings goals, and dreams should absolutely be factored in. Our calculator is responsive and will determine what you cost and how much money you need to earn to afford your desired life. If you entered your current earnings, the forecaster will project your net earnings and compare your current situation to your capital demand situation.</p>
        <br>
    </div>
</template>

<script>
export default {
    name: 'HelpPage'
}
</script>

<style>


    .helpContent{
        border: 1px solid white;
        border-radius: 5px;
        color: rgb(0, 0, 0);
        background-color: white;
        max-width: 70%;
        font-family: 'PT Sans', sans-serif;
    
    }


</style>