<template>
    <div id = "aboutContainer">
        <div>
            <h1>About</h1>
            <p>What I Cost is a free budget application designed to help individuals and families see their costs more clearly. Currently, It allows any United States based users to organize their financial obligations and elections, see the monthly and yearly summed costs, and forecast the annual capital demand needed to pay for those costs. This forecast also calculates expected tax bills and deferred retirement savings. </p>
            <p>This website and budget app were independently developed by me, Ben Ashwell. I am the founder, chief and soloprenuer (so far) of this venture under the name Capfores. Please support this effort by sharing my site with as many people as you can. I built this to help people figure out better what they need for, at the very least, financial bouyancy. We all have to pay the bills, peace of mind is easier knowing you will be able to cover those bills.</p>
            <p>If, as my footer asks, you find my site valuable, please support me here at my <a id = "aboutA" href=https://www.buymeacoffee.com/capfores>buy me a coffee account</a>! Wishing for you and your family the best of health and prosperity.</p>
        </div>
        <div>
            <h4>Disclaimers</h4>
            <p>
                Usage Disclaimer: Please read whaticost's usage disclaimer carefully. We reserve the right to change this Website Disclaimer at any time without notice, and by using the Website and its Content you are agreeing to the Website Disclaimer as it appears, whether or not you have read this language. If you do not agree with this Website Disclaimer, please do not use our Website or its Content.
                This website is owned by Capfores LLC.
            </p>
            <p>
                Forecast Dislaimer: free to use personal finance data forecaster service is not a certain outcome, the most common earned income tax ramifications are accounted for, but the tax code is broad and you may be affected by factors not included here. Users must also   
            </p>
            <p>
                <strong>FOR EDUCATIONAL AND INFORMATIONAL PURPOSES ONLY</strong><br>
                The information provided in or through our Website is for educational and informational purposes only and is made available to you as a free self-help tool for your own use.
            </p>
            <p>
                <strong>NOT LEGAL OR FINANCIAL ADVICE</strong><br>
                The information contained in our Website is not intended to be a substitute for financial advice that can be provided by your own accountant and/or financial advisor. We cannot be held responsible for any errors or omissions, and we accept no liability whatsoever for any loss or damage howsoever arising. The law varies by state, and it is constantly changing, and therefore it affects each individual in different ways. As a result, it is recommended to seek outside financial counsel relating to your specific circumstances as needed. You are hereby advised to consult with your tax consultant and/or accountant for any and all questions and concerns you have, may have, or hereafter have regarding your own income and taxes, and any and all information presented by our Website, Programs or Services pertaining to your specific financial situation. Tax data is sourced from https://taxfoundation.org/state-tax/individual-income-payroll-taxes/ and tax calculators are based on the publicly available information about income taxation, deductions, and credits. They cover the most common circumstances income tax filers deal with to get users a close “ballpark” estimate or forecast.
            </p>
            <p>
                <strong>NO ENDORSEMENT</strong><br>
                References or links in my Website to the information, opinions, advice, programs, products or services of any other individual, business or entity does not constitute my formal endorsement. I am merely sharing this information as a self-help resource only. I am not responsible for the website content, blogs, e-mails, videos, social media, programs, products and/or services of any other person, business or entity that may be linked or referenced on my Website. Conversely, should my Website link appear in any other individual’s, business’s or entity’s website, program, product or services, it does not constitute my formal endorsement of them, their business or their website either.
            </p>
        </div>
        <div>
            <p>
            <strong>Questions and Comments:</strong><br>
            Please reach out via email with your question and comments to bashwell@capfores.com
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutPage"
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri&family=PT+Sans&family=Zen+Antique+Soft&display=swap');

@media all and (max-width: 800px){

#aboutContainer{
    background-color: white;
    color: black;
    max-width: 70%;
    overflow-y: scroll;
}

p{
    width: max(55ch, 275px);
    font-size: 18px;
}

div{
    font-family: 'PT Sans', sans-serif;
}

}

@media all and (min-width: 801px){

#aboutContainer{
    background-color: white;
    color: black;
    width: auto;
    max-width: 70%;
}

p{
    width: max(120ch, 600px);
    font-size: 18px;
}

div{
    font-family: 'PT Sans', sans-serif;
}

#aboutA{
    color: black;
}

}


</style>
