<template>
    <div class = "containerCalc">
        <div class = "sessInfoContainer">
            <div class = "sessInfoDisplay">
                <h1 class = "serviceTitle">Costs and Income Tax Forecasting - United States of America</h1>
                <table class = "sessInfoTable" id = "sessInfoHomeTable">
                    <tr>
                        <th>Session Info</th> <th>State</th><th>Filing</th> <th></th>
                    </tr>
                    <tr>
                        <td>{{userTableTitle}}</td>
                        <td> <select v-model = "sessInfo.state" @change = "summedCost"><option disabled value="">Select</option><option v-for = "state in Object.keys(st_Tx)" :key= "state">{{state}}</option></select> </td>
                        <td><select v-model = "sessInfo.filing" @change = "standard_Tax_Deduction_Set(sessInfo.filing); summedCost; setStateFiling()"> <option disabled value="">Select</option><option v-for = "filing in Object.keys(federalTaxBrackets)" :key= "filing">{{filing}}</option></select></td> 
                        <td><button v-if = "moreUserInfo === false" @click = "moreUserInfo = !moreUserInfo">{{moreButton}}</button><button v-else @click = "moreUserInfo = !moreUserInfo">{{lessButton}}</button></td> 
                    </tr>
                </table>                
                <div class = "usersInfoInput"  v-if= "moreUserInfo == true">
                    <table class = "sessInfoTable" id = "userInfoTable">
                        <thead><strong>Set Your Information</strong></thead>
                        <tr>
                            <td>Employment Type <span title = "Employee (W-2) or Contractor (10-99)">(?)</span></td>
                            <td> <select v-model = "sessInfo.employment_Type" @change = "summedCost"> <option disabled value="">Select</option><option v-for = "type in Object.keys(employmentStatus)" :key = "type">{{type}}</option> </td>
                        </tr>
                        <tr>
                            <td><label>Tax Deductions</label></td>
                            <td><select v-model = "sessInfo.tax_Deductions" class = "input_Box" @change = "standard_Tax_Deduction_Set(sessInfo.filing)">
                                <option disabled value = ''>Select</option>
                                    <option v-for = "(ded, index) in tax_Deductions_List" :value = "ded" :key = "index">{{ded}}</option> 
                                </select>
                                    <div v-if = "sessInfo.tax_Deductions == 'Itemized Deduction'">
                                        <label>{{dedItemText}}</label><br>
                                        <input type="text" max-width="60px" v-model = "sessInfo.itemizedTaxDed" @keypress = "numFilt" class = "input_Box" @change = "standard_Tax_Deduction_Set(sessInfo.filing)">
                                    </div>
                            </td>
                        </tr>
                        <tr>
                            <td><label>Federal Tax Credits Estimate <span title = "Estimate of Qualified Federal Tax Credits, Such as Child Tax Credit">(?)</span></label></td>
                            <td><input type = "text" v-model = "sessInfo.fedTaxCredits" @keypress = "numFilt" @change = "aggTaxCredits" class = "input_Box"></td>
                        </tr>
                        <tr>
                            <td><label>State Tax Credits Estimate <span title = "Estimate of Qualified State Tax Credits">(?)</span></label></td>
                            <td><input type = "text" v-model = "sessInfo.stateTaxCredits" @keypress = "numFilt" @change = "aggTaxCredits" class = "input_Box"></td>
                        </tr>
                        <tr>
                            <td><label>Tax-Deferred Retirement Contribution Percentage(%)</label></td>
                            <td><input type="text" v-model = "sessInfo.retContibs" @keypress = "numFilt" class = "input_Box"></td><br>
                        </tr>
                        <tr>
                            <td><label>Estimate of Sum of Any Other Adjustments to Income</label></td>
                            <td><input type="text" v-model = "sessInfo.income_Adjustments" title="If you have any, many kinds of workers may not, reference your own history" @keypress = "numFilt" class = "input_Box"></td><br>
                        </tr>
                        <tr>
                            <td><label>Payroll Deductions Per Pay Period <span title = "Estimate of Non-Tax Recurring Per Paycheck Deductions, e.g. Workers' Comp, Workplace Based Insurance Programs">(?)</span></label></td>
                            <td><input type="text" v-model = "sessInfo.payrollDeductions" @keypress = "numFilt" @change = "aggDeds" class = "input_Box"></td>
                        </tr>
                        <tr>
                            <td><label>Pay Frequency <span title = "How often are you paid?">(?)</span></label></td>
                            <td><select type="text" v-model = "sessInfo.pDFrq" @keypress = "numFilt" @change = "aggDeds" class = "input_Box">
                                    <option v-for = "frq in Object.keys(pDFrqsObj)" :key = "frq" @change = "aggDeds">{{frq}}</option>
                                </select></td>
                        </tr>
                        <tr>
                            <td><label>Local Tax Rate % (If Any)</label></td>
                            <td><input type = "text" v-model = "sessInfo.local_Income_Tax_Rate" class = "input_Box"></td>
                        </tr>
                        <tr>
                            <td><label>Current Wages</label></td>
                            <td><span>$<input type="text" v-model = "sessInfo.pay" @keypress = "numFilt" @change = "calcTotalAnnPay" width = "60px"> per 
                            <select name="payFrq" id="payFrq" v-model = "sessInfo.payFrequency" @change = "calcTotalAnnPay" class = "input_Box">
                            <option v-for = "frq in Object.keys(payIntervals)" :key = "frq" @change = "calcTotalAnnPay">{{frq}}</option></select></span></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    <div class = "calcsAndData">
        <div id = "capitalDemandContainer" v-if = "calcSelection == 'Capital Demand'">
            <div id = "forecastCalc">
                <h3>Build Your Budget - What Do You Need Money For? How Often?<br><button v-if = "tableOn === false" @click = "tableOn = !tableOn">{{showTableButton}}</button><button v-else @click = "tableOn = !tableOn">{{hideTableButton}}</button></h3>
                <table id = "expTable" class = "sessInfoTable" v-if = "tableOn === true">
                    <tbody>
                    <tr id = "inputHeaders"> <th class = "controlButton">+</th> <th class = "controlButton">↑</th> <th class = "controlButton">↓</th> <th class = "controlButton"></th> <th>Description</th>  <th>Amount</th> <th class = "totHead" title = "Frequency">Frq</th> <th>Per</th> <th class = "totHead">Monthly</th> <th class = "totHead">Yearly</th> <th class = "controlButton">-</th> </tr>
                    <tr v-for="(expense, index) in expenses" :key = 'index' v-bind:class = "{'even' : index % 2 !== 0}" class = "expenseRows">   

                        <td class = "controlButton">
                            <span @click = "addNewExpense(index+1)" title = "Add Row">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#085225" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                                </svg>
                            </span>
                        </td>

                        <td v-bind:class="{disabled: index==0}" class = "clickButton"><span @click = "move(index, index-1)" title = "Move Row Up" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                </svg></span></td>

                        <td><span v-bind:class="{disabled: index==expenses.length-1}" @click = "move(index, index+1)" title = "Move Row Down" class = "clickButton"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                </svg></span></td>

                        <td class = "controlButton">{{index + 1}}</td>

                        <td><input list="bill_Descriptions_" id="billDes" class = "exp_Input" name = "bill_description" v-model="expense.name" title = "Pick Description From List or Type Custom" width = "105px">
                            <datalist id = "bill_Descriptions_">
                                <option v-for = "description in billDescriptions" :key= "description">{{description}}</option>
                            </datalist>
                        </td>    

                        <td width = 75px><label for = "expAmount">$</label><input type = "text" id = "expAmount" placeholder = 0.00 v-model="expense.amount" @keypress = "numFilt" @change = "summedCost" title = "Please Enter a Number" width = 70px;></td> 
                        <td>
                            <select v-model="expense.freqencyNumber" @change = "summedCost">
                            <option disabled value="">Select</option>
                            <option v-for = "frq in frequenciesNumber" :key = "frq">{{frq}}</option></select>
                        </td>
                        <td>
                        <select v-model="expense.frequency" @change = "summedCost" id = "frqOpts">
                            <option disabled value="">Select</option>
                            <option v-for = "frq in frequencies" :key = "frq">{{frq}}</option></select>
                        </td>
                        <td style = "text-align:center"> ${{expense.monthlyFormatted}} </td>
                        <td style = "text-align:center"> ${{expense.annuallyFormatted}} </td>
                        <td class = "clickButton"><span @click = "deleteExpense(index)" title = "Delete Row"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
                          </svg></span></td>
                    </tr>
                    </tbody>
                </table> 
            </div>
            <div id = "forecastData"> 
                    <table class = "sessInfoTable" id = "firstTable">
                            <tr>
                                <td>Forecast Totals</td>
                                <td>Yearly</td>
                                <td>Monthly</td>
                                <td title = "@ 40Hrs/Wk, 52Wks/Yr">Hourly (?)</td>
                            </tr>
                            <tr>
                                <td title = "Sums of Expenses">Expense Costs (?)</td>
                                <td><strong>${{formatAnnualTotal}}</strong></td>
                                <td><strong>${{formatMonthlyTotal}}</strong></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td title = "Earned Income Demanded to Satisfy Expenses">Capital Demand (?)</td>
                                <td><strong>${{calibratorSalary}}</strong></td>
                                <td><strong>${{calibrateMonthly}}</strong></td>
                                <td><strong>${{calibratorHourly}}</strong></td>
                            </tr>
                            <tr>
                                <td><button v-if= "moreData === false" @click = "moreData = !moreData; dataExpCol()">{{moreDataButton}}</button> <button v-else @click = "moreData = !moreData; dataExpCol()">{{lessDataButton}}</button> </td>
                            </tr>
                            <tr v-if = "moreData == true">
                                <td>Gross Pay</td>
                                <td>${{returnAnnualPay}}</td>
                                <td>${{returnMonthlyPay}}</td>
                                <td></td>
                            </tr>
                            <tr v-if = "moreData == true">
                                <td>Net Pay</td>
                                <td>${{(takeHomeForecast)}}</td>
                                <td>${{(takeHomeForecastMonthly)}}</td>
                                <td></td>
                            </tr>
                            <tr v-if = "moreData == true">
                                <td>Net - Expenses Difference</td>
                                <td :class = "{negative: isNegative}">${{diffSalaryAnnual}}</td>
                                <td :class = "{negative: isNegative}">${{diffSalaryMonth}}</td>
                                <td></td>
                            </tr>
                            <tr v-if = "moreData == true">
                                <td>Gross - Capital Demand Difference</td>
                                <td :class = "{negative: isNegative}">${{diffGCDAnnual}}</td>
                                <td :class = "{negative: isNegative}">${{diffGCDMonth}}</td>
                            </tr>
                    </table>
                    <div v-if = "moreData == true" :class = "{disabled: !moreData}" id = "dataMax">
                        <table id = "mainForecastData"  class = "sessInfoTable">
                            <tbody> 
                                <tr>
                                    <th colspan="3">Capital Demand Tax Forecast Data</th>
                                </tr>   
                                <tr><th>Taxes Due ($)</th><th>Tax Projections</th><th>% of Gross Wages</th></tr>
                                <tr>
                                    <td title = "Applicable on upto first $142,800.00 of income.">Social Security</td>
                                    <td>${{ssnTaxAmtFmt}}</td>
                                    <td title = "6.2% Employee, 12.4% Self-Employeed Affect Taxable Wages">{{sessInfo.ssnPerc}}%</td>
                                </tr>
                                <tr>
                                    <td title = "Employee Rate 1.45% on first $200,000 taxable incomes, 2.35% on remaining incomes. Self-Employed Rate 2.9% first $200,000 t. i., 3.8% remaining incomes.">Medicare</td>
                                    <td>${{medicareTaxAmtFmt}}</td>
                                    <td>{{sessInfo.medicrPerc}}%</td>
                                </tr>
                                <tr>
                                    <td>Federal Income Taxes</td>
                                    <td>${{fedTaxesSumFmt}}</td>
                                    <td>{{fedTaxesPercent}}%</td>
                                </tr>
                                <tr>
                                    <td>State Income Taxes</td>
                                    <td>${{stateTaxesSumFmt}}</td>
                                    <td>{{stateTaxesPercent}}%</td>
                                </tr>
                                <tr>
                                    <td>Local Income Taxes</td>
                                    <td>${{localTaxesSumFmt}}</td>
                                    <td>{{localTaxEff}}%</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class = "sessInfoTable">
                            <tr>
                                <th colspan="3">Other Factors</th>
                            </tr>   
                            <tr>
                                <td>Annual Tax-Deferred Retirement Savings</td><td>${{sessInfo.retContrbAmt}}</td>
                            </tr>
                            <tr>
                                <td>Federal Tax Deduction</td><td>${{standard_Tax_Deduction_Set((sessInfo.filing))}}</td>
                            </tr>
                            <tr>
                                <td>Federal Tax Credits Estimate</td><td>${{sessInfo.fedTaxCredits}}</td>
                            </tr>
                            <tr title = "If any, check previous tax returns to see if you had any adjustments to taxable federal income that you expect to receive again in the future">
                                <td>Additional Federal Income Adjustments</td><td>${{sessInfo.income_Adjustments}}</td>
                            </tr>
                            <tr>
                                <td>State Tax Deduction</td> <td>${{returnStateDeduction}}</td>
                            </tr>                            
                            <tr>
                                <td>State Tax Credits Estimate</td><td>${{sessInfo.stateTaxCredits}}</td>
                            </tr>
                            <tr v-if = "sessInfo.annualTotalDeductions > 0">
                                <td>Annual Payroll Deductions</td><td>${{sessInfo.annualTotalDeductions}}</td>
                            </tr>
                        </table>

                        <table class="sessInfoTable">
                            <tr><th colspan="3">Federal Tax Brackets, Filing: {{sessInfo.filing}}</th></tr>
                            <tr>
                                <th>Bracket No.</th>
                                <th>Tax Rate</th>
                                <th>Bracket Ceiling<span title = "bracketCeilingTitle">(?)</span></th>
                            </tr>
                            <tr v-for ="(filing, index) in federalTaxBrackets[sessInfo.filing]" :key ="filing">
                                <td>{{index + 1}}</td>
                                <td>{{(filing.rate * 100).toFixed(2).toLocaleString('en')}}%</td>
                                <td v-if = "filing.ceiling != false">Bracket Top: ${{filing.ceiling.toLocaleString('en')}}</td><td v-else>No Limit</td>
                            </tr>
                        </table>

                        <table class="sessInfoTable">
                            <tr><th colspan="3">State Tax Brackets, {{sessInfo.state}}</th></tr>
                            <tr>
                                <th>Bracket No.</th>
                                <th>Tax Rate</th>
                                <th>Bracket Ceiling<span title = "stateBracketCeilingTitle">(?)</span></th>
                            </tr>
                            <tr v-for ="(bracket, index) in st_Tx[sessInfo.state][sessInfo.stateFiling].brackets" :key="bracket">
                                <td>{{index + 1}}</td>
                                <td>{{(bracket.rate * 100).toFixed(2).toLocaleString('en')}}%</td>
                                <td  v-if="bracket.ceiling === 'Flat Tax'">Flat Tax State</td><td v-else-if="bracket.rate === 0">No State Income Tax</td><td v-else-if = "bracket.ceiling != false">Bracket Top: ${{Number(bracket.ceiling).toFixed(2).toLocaleString('en')}}</td><td v-else>No Limit</td>
                            </tr>
                        </table>
                        <h6><a href = https://taxfoundation.org/publications/federal-tax-brackets/>Federal Tax Information Source</a></h6>
                        <h6><a href = https://taxfoundation.org/publications/state-individual-income-tax-rates-and-brackets/>State Tax Information Source</a></h6>
                </div>
                <div id = "downloadButton">
                    Capital Forecast Report PDFs Coming Soon!
                    <button @click = 'getPDF()'>Capital Demand Report</button>
                </div>
            </div>      
        </div>      
            <div id = "takeHomeEstimator" :class = "{disabled: calcSelection != 'Take Home'}" v-else>
                <h3>Take Home / Net Pay Estimator</h3> 
                    <p id="takeHomePara">Enter Your Current Wages</p>
                <span>$<input type="text" v-model = "sessInfo.pay" @keypress = "numFilt" @change = "calcTotalAnnPay" width = "60px"> per 
                <select name="payFrq" id="payFrq" v-model = "sessInfo.payFrequency" @change = "calcTotalAnnPay" class = "input_Box">
                    <option v-for = "frq in Object.keys(payIntervals)" :key = "frq" @change = "calcTotalAnnPay">{{frq}}</option>
                </select><span></span></span>
                <table class = "sessInfoTable">
                    <tr>
                        <th>Projected Gross Earnings</th>
                        <th>Projected Net Pay</th>
                    </tr>
                    <tr>
                        <td>{{(sessInfo.annualPay).toFixed(2).toLocaleString('en')}}$/yr</td>
                        <td>{{(takeHomeForecast).toFixed(2).toLocaleString('en')}}$/yr</td>
                    </tr>
                </table>      
                
                <div id = "takeHomeData">

                    <table class = "sessInfoTable">
                        <tr>
                            <th>Taxes Due</th>
                            <th>$/Year</th>
                            <th>% of Yearly Demand</th>
                        </tr>
                        <tr>
                            <td>Federal</td>
                            <td>${{(fedTaxesDueTH).toFixed(2).toLocaleString('en')}}</td>
                            <td>${{(((fedTaxesDueTH)/(sessInfo.annualPay))*100).toFixed(2).toLocaleString('en')}}%</td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>${{(stateTaxesDueTH).toFixed(2).toLocaleString('en')}}</td>
                            <td>${{(((stateTaxesDueTH)/(sessInfo.annualPay))*100).toFixed(2).toLocaleString('en')}}%</td>
                        </tr>
                        <tr>
                            <td>Local Taxes Due</td>
                            <td>${{(localTaxesDueTH).toFixed(2).toLocaleString('en')}}</td>
                            <td>${{(((localTaxesDueTH)/(sessInfo.annualPay))*100).toFixed(2).toLocaleString('en')}}%</td>
                        </tr>
                        <tr>
                            <td>FICA</td>
                            <td>${{(ficaTaxesDueTH).toFixed(2).toLocaleString('en')}}</td>
                            <td>${{(((ficaTaxesDueTH)/(sessInfo.annualPay))*100).toFixed(2).toLocaleString('en')}}%</td>
                        </tr>
                    </table>

                    <table class = "sessInfoTable">
                        <tr>
                            <th colspan="3">Other Factors</th>
                        </tr>   
                        <tr>
                            <td>Tax-Deferred Retirement Savings</td>
                            <td>{{sessInfo.retContibs}}%, ${{(sessInfo.retContrbAmt).toLocaleString('en')}}/year</td>
                        </tr>
                        <tr>
                            <td>Payroll Deductions</td>
                            <td>${{sessInfo.annualTotalDeductions}}/year</td>
                        </tr>
                        <tr>
                            <td>Federal Tax Deduction</td>
                            <td>${{standard_Tax_Deduction_Set((sessInfo.filing))}}</td>
                        </tr>
                        <tr>
                            <td>Federal Tax Credits Estimate</td
                            ><td>${{(sessInfo.fedTaxCredits).toLocaleString('en')}}</td>
                        </tr>
                        <tr title = "If any, check previous tax returns to see if you had any adjustments to taxable federal income that you expect to receive again in the future">
                            <td>Additional Federal Income Adjustments</td>
                            <td>${{(sessInfo.income_Adjustments).toLocaleString('en')}}</td>
                        </tr>
                        <tr>
                            <td>State Tax Deduction</td>
                            <td>${{(returnStateDeduction).toLocaleString('en')}}</td>
                        </tr>                            
                        <tr>
                            <td>State Tax Credits Estimate</td>
                            <td>${{(sessInfo.stateTaxCredits).toLocaleString('en')}}</td
                        ></tr>
                        <tr v-if = "sessInfo.annualTotalDeductions > 0">
                            <td>Annual Payroll Deductions</td>
                            <td>${{(sessInfo.annualTotalDeductions).toLocaleString('en')}}</td>
                        </tr>
                    </table>

                        <table class="sessInfoTable">
                            <tr><th colspan="3">Federal Tax Brackets, Filing: {{sessInfo.filing}}</th></tr>
                            <tr>
                                <th>Bracket No.</th>
                                <th>Tax Rate</th>
                                <th>Bracket Ceiling<span title = "bracketCeilingTitle">(?)</span></th>
                            </tr>
                            <tr v-for ="(filing, index) in federalTaxBrackets[sessInfo.filing]" :key ="filing">
                                <td>{{index + 1}}</td>
                                <td>{{(filing.rate * 100).toFixed(2).toLocaleString('en')}}%</td>
                                <td v-if = "filing.ceiling != false">Bracket Top: ${{filing.ceiling.toLocaleString('en')}}</td><td v-else>No Limit</td>
                            </tr>
                        </table>

                        <table class="sessInfoTable">
                            <tr><th colspan="3">State Tax Brackets, {{sessInfo.state}}</th></tr>
                            <tr>
                                <th>Bracket No.</th>
                                <th>Tax Rate</th>
                                <th>Bracket Ceiling<span title = "stateBracketCeilingTitle">(?)</span></th>
                            </tr>
                            <tr v-for ="(bracket, index) in st_Tx[sessInfo.state][sessInfo.stateFiling].brackets" :key="bracket">
                                <td>{{index + 1}}</td>
                                <td>{{(bracket.rate * 100).toFixed(2).toLocaleString('en')}}%</td>
                                <td  v-if="bracket.ceiling === 'Flat Tax'">Flat Tax State</td><td v-else-if="bracket.rate === 0">No State Income Tax</td><td v-else-if = "bracket.ceiling != false">Bracket Top: ${{bracket.ceiling.toLocaleString('en')}}</td><td v-else>No Limit</td>
                            </tr>
                        </table>
                        <h6>Federal Tax Information Source: https://taxfoundation.org/publications/federal-tax-brackets/</h6>
                        <h6>State Tax Information Source: https://taxfoundation.org/publications/state-individual-income-tax-rates-and-brackets/</h6>
                </div>
            </div>
        </div>       
    </div>
</template>

<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5515755819474383"
     crossorigin="anonymous"></script>

<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-ZZRH17LP8W"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-ZZRH17LP8W');
</script>

<script>
    //import jsPDF from 'jsPDF'


    export default{ 
        name: 'CalcComp',
        props: {
            chartId: {
                type: String,
                default: 'doughnut-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 400
            },
            cssClasses: {
                type: String,
                default: ''
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Array,
                default: () => []
            }
        },
        data(){ 
            return{

                //Expenses Object
                expenses: [{
                    name: 'Housing',
                    amount: 0,
                    frequency: 'Month',
                    freqencyNumber: 1,
                    frqPick(){
                        if (this.frequency === "Week"){return 52}
                        else if (this.frequency === "Month"){return 12}
                        else if (this.frequency === "Every Other Month"){return 6}
                        else if (this.frequency === "Quarter"){return 4}
                        else if (this.frequency === "Biannual"){return 2}
                        else if (this.frequency === "Year"){return 1}
                        else if (this.frequency === "Day"){return 365}
                        else {return 0}},
                    yearlyFrq(){return this.frqPick() * this.freqencyNumber},
                    monthly: 0, 
                    annually: 0,
                    monthlyFormatted: 0,
                    annuallyFormatted: 0
                }],

                //User / Session Info Object
                sessInfo: {
                    state: 'Alabama',
                    local_Income_Tax_Rate: 0.5,
                    tax_Deductions: 'Standard Deduction',
                    tax_Deduction: 12400,
                    stax_Deduction: this.tax_Deductions === 'Standard Deduction'? this.tax_Deduction : this.tax_Deductions === "Itemized Deduction"? this.itemizedTaxDed : 0,
                    curStandardDeduction: this.curStndrdDed,
                    itemizedTaxDed: 0,
                    tax_Credits: this.totalTaxCredits,
                    fedTaxCredits: 0,
                    stateTaxCredits: 0,
                    totalTaxCredits: 0,
                    employment_Type: "Employee",
                    fica_Rate: .0765,
                    filing: 'Single',
                    retContibs: 3,
                    retContrbAmt: 0,
                    payrollDeductions: 0,
                    pDFrq: 'Biweekly',
                    annualTotalDeductions: 0,
                    income_Adjustments: 0,
                    stateFiling: "single",
                    pay: 42000,
                    payFrequency: "Year",
                    annualPay: 42000, 
                    sessInfoStateDeduction: this.returnStateDeduction,
                    ssnAmt: 0, 
                    ssnPerc: 0,
                    medicrAmt: 0,
                    medicrPerc: 0
                }, 
                pageIDs: { 
                    helpPage: "helpPage",
                    aboutPage: "aboutPage",
                    calculatorPage: "calcPage",
                },
                payIntervals: {"Year": 1, "Month": 12, "Week": 52, "Hour": 2080},
                calcTypes: {'Capital Forecast': 'Capital Forecast', 'Take Home Estimate': 'Take Home Estimate'},
                calcSelection: "Capital Demand",
                contentSelection: 'incomeForecaster',
                tax_Deductions_List: ["Standard Deduction", "Itemized Deduction"],
                billDescriptions: ["Housing", "Groceries", "Transportation", "Medicine", "Recreations", "Health Insurance", "Car Insurance", "Life Insurance", "Phone", "Electricity", "Water", "Trash", "Sewege", "Gas / Heat / Utility", "Credit Card", "Student Loans", "Grocery", "Savings", "Disposable Income", "Vacation Fund", "Hobbies",],
                frequencies: ['Week', 'Month', "Every Other Month", 'Quarter', 'Biannual', 'Year'],
                frequenciesNumber: [1, 2, 3, 4, 5],
                pDFrqsObj: {"Weekly": 52, "Biweekly": 24, "Monthly": 12},
                stateTaxesDue: 0, 
                fedTaxesDue: 0,
                localTaxesDue: 0,
                localTaxEff: 0,
                ficaTaxesDue: 0,
                dedItemText: "Deduction Estimate $",
                moreData: false,
                moreDataButton: 'More ↓',
                lessDataButton: 'Less ↑',
                moreUserInfo: false,
                tableOn: true,
                isNegative: true,
                moreButton: 'More ↓',
                lessButton: 'Less ↑',
                showTableButton: "Expense Table ↓",
                hideTableButton: "Expense Table ↑",
                bracketCeilingTitle: "Bracket No. 1 starts at zero and bracket ceiling refers to highest dollar amount the bracket rate applies. United States has a progressive tax code, the rates apply to ranges of dollar amounts, subtract a bracket ceiling from the previous bracket's ceiling to find the range.",
                userTableTitle: "User(s)'s Details",
                ficaTaxesDueTH: 0, localTaxEffTH: 0, localTaxesDueTH: 0, stateTaxesDueTH: 0, fedTaxesDueTH: 0,
                employmentStatus: {"Employee": {"ssn": 0.062, "medicare": 0.0145}, "Self-Employed": {"ssn": 0.062, "medicare": 0.0145}},
                standardDeductions: {"Single": 12550, "Married Filing Jointly": 25100, "Head of Household":18800},
                federalTaxBrackets: {
                    "Single": /*"brackets"*/ [{rate: 0.1, ceiling: 10275}, {rate: 0.12, ceiling: 41775}, {rate: 0.22, ceiling: 89075}, {rate: 0.24, ceiling: 170050}, {rate: 0.32, ceiling: 215950}, {rate: 0.35, ceiling: 539900}, {rate: 0.37, ceiling: false}], 
                    "Married Filing Jointly": /*{ "brackets":*/ [{rate: 0.1, ceiling: 20550}, {rate: 0.12, ceiling: 83550}, {rate: 0.22, ceiling: 178150}, {rate: 0.24, ceiling: 340100}, {rate: 0.32, ceiling: 431900}, {rate: 0.35, ceiling: 647850}, {rate: 0.37, ceiling: false}],
                    "Head of Household": /*{ "brackets":*/ [{ rate: 0.1, ceiling: 14650}, {rate: 0.12, ceiling: 55900}, {rate: 0.22, ceiling: 89050}, {rate: 0.24, ceiling: 170050}, {rate: 0.32, ceiling: 215950}, {rate: 0.35, ceiling: 539900}, {rate: 0.37, ceiling: false}]
                  },
                st_Tx:{
                    "Alabama": { 
                        "single": { stan_Ded: 2500, brackets: [{ rate: 0.02, ceiling: 500}, { rate: 0.04, ceiling: 3000}, { rate: 0.05, ceiling: false}]},
                        "married": { stan_Ded: 7500, brackets: [{ rate: 0.02, ceiling: 1000}, { rate: 0.04, ceiling: 6000}, { rate: 0.05, ceiling: false}]}
                    },
                    "Alaska": {
                        "single": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 500}]},
                        "married": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 1000}]}
                    },                    
                    "Arizona": {
                        "single": {stan_Ded: 12950, brackets: [{ rate: 0.0259, ceiling: 27808}, { rate: 0.0334, ceiling: 55615}, { rate: 0.0417, ceiling: 166843}, { rate: 0.045, ceiling: false}]},
                        "married": { stan_Ded: 25900, brackets: [{ rate: 0.0259, ceiling: 55615}, { rate: 0.0334, ceiling: 111229}, { rate: 0.0417, ceiling: 333684}, { rate: 0.045, ceiling: false}]}
                    },
                    "Arkansas": {
                        "single": { stan_Ded: 2200, brackets: [{rate: 0.02, ceiling: 4300}, { rate: 0.04, ceiling: 8500}, { rate: 0.055, ceiling: false}]},
                        "married": { stan_Ded: 4400, brackets: [{ rate: 0.02, ceiling: 4300}, { rate: 0.04, ceiling: 8500}, { rate: 0.055, ceiling: false}]}
                    },
                    "California": {
                        "single": { stan_Ded: 4803, brackets: [{ rate: 0.01, ceiling: 9325}, { rate: 0.02, ceiling: 22107}, { rate: 0.04, ceiling: 34892}, { rate: 0.06, ceiling: 48435}, { rate: 0.08, ceiling: 61214}, { rate: 0.093, ceiling: 312686}, { rate: 0.103, ceiling: 375221}, { rate: 0.113, ceiling: 625368}, { rate: 0.123, ceiling: 1000000}, { rate: 0.133, ceiling: false}]},
                        "married": { stan_Ded: 9606, brackets: [{ rate: 0.01, ceiling: 18650}, { rate: 0.02, ceiling: 44214}, { rate: 0.04, ceiling: 69784}, { rate: 0.06, ceiling: 96870}, { rate: 0.08, ceiling: 122428}, { rate: 0.093, ceiling: 625372}, { rate: 0.103, ceiling: 750442}, { rate: 0.113, ceiling: 1000000}, { rate: 0.123, ceiling: 1250738}, { rate: 0.133, ceiling: false}]}
                    },
                    "Colorado": {
                        "single": {stan_Ded: 12950, brackets: [{rate: 0.0455, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 25900, brackets: {rate: 0.0455, ceiling: "Flat Tax"}}
                    },
                    "Conneticut": {
                        "single": { stan_Ded: 15000, brackets: [{ rate: 0.03, ceiling: 10000}, { rate: 0.05, ceiling: 50000}, { rate: 0.055, ceiling: 100000}, { rate: 0.06, ceiling: 200000}, { rate: 0.065, ceiling: 250000}, { rate: 0.069, ceiling: 500000}, {rate: 0.0699, ceiling: false}]},
                        "married": { stan_Ded: 24000, brackets: [{ rate: 0.03, ceiling: 20000}, { rate: 0.05, ceiling: 100000}, { rate: 0.055, ceiling: 200000}, { rate: 0.06, ceiling: 400000}, { rate: 0.065, ceiling: 500000}, { rate: 0.069, ceiling: 1000000}, {rate: 0.0699, ceiling: false}]}
                    },
                    "Delaware": {
                         "single": { stan_Ded: 3250, brackets: [{rate: 0, ceiling: 2000}, {rate: 0.022, ceiling: 5000}, { rate: 0.039, ceiling: 10000}, { rate: 0.048, ceiling: 20000}, { rate: 0.052, ceiling: 25000}, { rate: 0.0555, ceiling: 60000}, { rate: 0.060, ceiling: false}]},
                        "married": { stan_Ded: 6500, brackets: [{rate: 0, ceiling: 2000}, {rate: 0.022, ceiling: 5000}, { rate: 0.039, ceiling: 10000}, { rate: 0.048, ceiling: 20000}, { rate: 0.052, ceiling: 25000}, { rate: 0.0555, ceiling: 60000}, { rate: 0.060, ceiling: false}]}
                    },
                    "Florida": {
                        "single": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 500}]},
                        "married": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 1000}]}
                    },
                    "Georgia": {
                        "single": { stan_Ded: 4600, brackets: [{rate: 0.01, ceiling: 750}, { rate: 0.02, ceiling: 2250}, { rate: 0.03, ceiling: 3750}, { rate: 0.04, ceiling: 5250}, { rate: 0.05, ceiling: 7000}, { rate: 0.0575, ceiling: false}]},
                        "married": { stan_Ded: 6000, brackets: [{ rate: 0.01, ceiling: 1000}, { rate: 0.02, ceiling: 3000}, { rate: 0.03, ceiling: 5000}, { rate: 0.04, ceiling: 7000}, { rate: 0.05, ceiling: 10000}, { rate: 0.0575, ceiling: false}]}
                    },
                    "Hawaii": {
                        "single": { stan_Ded: 2200, brackets: [{ rate: 0.014, ceiling: 2400}, { rate: 0.032, ceiling: 4800}, { rate: 0.055, ceiling: 9600}, { rate: 0.064, ceiling: 14400}, { rate: 0.068, ceiling: 19200}, { rate: 0.072, ceiling: 24000}, { rate: 0.076, ceiling: 36000}, { rate: 0.079, ceiling: 48000}, { rate: 0.0825, ceiling: 150000}, { rate: 0.09, ceiling: 175000}, { rate: 0.1, ceiling: 200000}, { rate: 0.11, ceiling: false}]},
                        "married": { stan_Ded: 4400, brackets: [{ rate: 0.014, ceiling: 4800}, { rate: 0.032, ceiling: 9600}, { rate: 0.055, ceiling: 19200}, { rate: 0.064, ceiling: 28800}, { rate: 0.068, ceiling: 38400}, { rate: 0.072, ceiling: 48000}, { rate: 0.076, ceiling: 72000}, { rate: 0.079, ceiling: 96000}, { rate: 0.0825, ceiling: 300000}, { rate: 0.09, ceiling: 350000}, { rate: 0.1, ceiling: 400000}, { rate: 0.11, ceiling: false}]}
                    },
                    "Idaho": {
                        "single": { stan_Ded: 12550, brackets: [{rate: 0.01125, ceiling: 1568}, { rate: 0.03125, ceiling: 3136}, { rate: 0.03625, ceiling: 4704}, { rate: 0.04625, ceiling: 6272}, { rate: 0.05625, ceiling: 7840}, { rate: 0.06625, ceiling: 11760}, { rate: 0.06925, ceiling: false}]},
                        "married": { stan_Ded: 25100, brackets: [{ rate: 0.01125, ceiling: 3136}, { rate: 0.03125, ceiling: 6272}, { rate: 0.03625, ceiling: 9408}, { rate: 0.04625, ceiling: 12544}, { rate: 0.05625, ceiling: 15680}, { rate: 0.06625, ceiling: 23520}, { rate: 0.06925, ceiling: false}]}
                    },
                    "Illinois": {
                        "single": {stan_Ded: 2325, brackets: [{rate: 0.0495, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 4650, brackets: [{rate: 0.0495, ceiling: "Flat Tax"}]}
                    },
                    "Indiana": {
                        "single": {stan_Ded: 1000, brackets: [{rate: 0.0323, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 2000, brackets: [{rate: 0.0323, ceiling: "Flat Tax"}]}
                    }, 
                    "Iowa": {
                        "single": { stan_Ded: 2130, brackets: [{ rate: 0.0033, ceiling: 1676}, { rate: 0.0067, ceiling: 3352}, { rate: 0.0225, ceiling: 6704}, { rate: 0.0414, ceiling: 15084}, { rate: 0.0563, ceiling: 25140}, { rate: 0.0596, ceiling: 33520}, { rate: 0.0625, ceiling: 50280}, { rate: 0.0744, ceiling: 75420}, { rate: 0.0853, ceiling: false}]},
                        "married": { stan_Ded: 5240, brackets: [{ rate: 0.0033, ceiling: 1676}, { rate: 0.0067, ceiling: 3352}, { rate: 0.0225, ceiling: 6704}, { rate: 0.0414, ceiling: 15084}, { rate: 0.0563, ceiling: 25140}, { rate: 0.0596, ceiling: 33520}, { rate: 0.0625, ceiling: 50280}, { rate: 0.0744, ceiling: 75420}, { rate: 0.0853, ceiling: false}]}
                    }, 
                    "Kansas": {
                        "single": { stan_Ded: 3000, brackets: [{rate: 0.031, ceiling: 15000}, { rate: 0.0525, ceiling: 30000}, { rate: 0.057, ceiling: false}]},
                        "married": { stan_Ded: 7500, brackets: [{ rate: 0.031, ceiling: 30000}, { rate: 0.0525, ceiling: 60000}, { rate: 0.057, ceiling: false}]}
                    },
                    "Kentucky": {
                        "single": {stan_Ded: 2690, brackets: [{rate: 0.05, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 5380, brackets: [{rate: 0.05, ceiling: "Flat Tax"}]}
                    },
                    "Louisiana": {
                        "single": { stan_Ded: 4500, brackets: [{rate: 0.02, ceiling: 12500}, { rate: 0.04, ceiling: 50000}, { rate: 0.06, ceiling: false}]},
                        "married": { stan_Ded: 9000, brackets: [{ rate: 0.02, ceiling: 25000}, { rate: 0.04, ceiling: 100000}, { rate: 0.06, ceiling: false}]}
                    },
                    "Maine": {
                        "single": { stan_Ded: 12550, brackets: [{rate: 0.058, ceiling: 22450}, { rate: 0.0675, ceiling: 53150}, { rate: 0.0715, ceiling: false}]},
                        "married": { stan_Ded: 25100, brackets: [{ rate: 0.058, ceiling: 44950}, { rate: 0.0675, ceiling: 106350}, { rate: 0.0715, ceiling: false}]}
                    },
                    "Maryland": {
                        "single": { stan_Ded: 4600, brackets: [{ rate: 0.02, ceiling: 1000}, { rate: 0.03, ceiling: 2000}, { rate: 0.04, ceiling: 3000}, {rate: 0.0475, ceiling: 100000}, { rate: 0.05, ceiling: 125000}, { rate: 0.0525, ceiling: 150000}, { rate: 0.055, ceiling: 250000}, { rate: 0.0575, ceiling: false}]},
                        "married": { stan_Ded: 6000, brackets: [{ rate: 0.02, ceiling: 1000}, { rate: 0.03, ceiling: 2000}, { rate: 0.04, ceiling: 3000}, {rate: 0.0475, ceiling: 150000}, { rate: 0.05, ceiling: 175000}, { rate: 0.0525, ceiling: 225000}, { rate: 0.055, ceiling: 300000}, { rate: 0.0575, ceiling: false}]}
                    },
                    "Massachusetts": {
                        "single": {stan_Ded: 4400, brackets: [{rate: 0.05, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 8800, brackets: [{rate: 0.05, ceiling: "Flat Tax"}]}
                    },
                    "Michigan": {
                        "single": {stan_Ded: 4900, brackets: [{rate: 0.0425, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 9800, brackets: [{rate: 0.0425, ceiling: "Flat Tax"}]}
                    },
                    "Minnesota": {
                        "single": { stan_Ded: 12525, brackets: [{rate: 0.0535, ceiling: 27230}, { rate: 0.068, ceiling: 89440}, { rate: 0.0785, ceiling: 166040}, { rate: 0.0985, ceiling: false}]},
                        "married": { stan_Ded: 12525, brackets: [{rate: 0.0535, ceiling: 39810}, { rate: 0.068, ceiling: 158140}, { rate: 0.0785, ceiling: 276200}, { rate: 0.0985, ceiling: false}]}
                    },
                    "Mississippi": {
                        "single": { stan_Ded: 2300, brackets: [{rate: 0.0, ceiling: 4000}, { rate: 0.03, ceiling: 5000}, { rate: 0.04, ceiling: 10000}, { rate: 0.05, ceiling: false}]},
                        "married": { stan_Ded: 4600, brackets: [{rate: 0.0, ceiling: 4000}, { rate: 0.03, ceiling: 5000}, { rate: 0.04, ceiling: 10000}, { rate: 0.05, ceiling: false}]}
                    },
                    "Missouri": {
                        "single": { stan_Ded: 12550, brackets: [{rate: 0, ceiling: 107}, { rate: 0.015, ceiling: 1073}, { rate: 0.02, ceiling: 2146}, { rate: 0.025, ceiling: 3219}, { rate: 0.03, ceiling: 4292}, { rate: 0.035, ceiling: 5365}, { rate: 0.04, ceiling: 6438}, { rate: 0.045, ceiling: 7511}, { rate: 0.05, ceiling: 8684}, { rate: 0.054, ceiling: false}]},
                        "married": { stan_Ded: 25100, brackets: [{ rate: 0, ceiling: 107}, { rate: 0.02, ceiling: 1073}, { rate: 0.025, ceiling: 2146}, { rate: 0.03, ceiling: 3219}, { rate: 0.035, ceiling: 4292}, { rate: 0.04, ceiling: 5365}, { rate: 0.04, ceiling: 6438}, { rate: 0.045, ceiling: 7511}, { rate: 0.05, ceiling: 8584}, { rate: 0.054, ceiling: false}]}
                    },
                    "Montana": {
                         "single": { stan_Ded: 4790, brackets: [{rate: 0.01, ceiling: 3100}, {rate: 0.02, ceiling: 5500}, {rate: 0.03, ceiling: 8400}, {rate: 0.04, ceiling: 11300}, {rate: 0.05, ceiling: 14500}, {rate: 0.06, ceiling: 18700}, { rate: 0.069, ceiling: false}]},
                        "married": { stan_Ded: 9580, brackets: [{rate: 0.01, ceiling: 3100}, {rate: 0.02, ceiling: 5500}, {rate: 0.03, ceiling: 8400}, {rate: 0.04, ceiling: 11300}, {rate: 0.05, ceiling: 14500}, {rate: 0.06, ceiling: 18700}, { rate: 0.069, ceiling: false}]}
                    },
                    "Nebraska": {
                        "single": { stan_Ded: 7100, brackets: [{rate: 0.0246, ceiling: 3340}, { rate: 0.0351, ceiling: 19900}, { rate: 0.0501, ceiling: 32210}, {rate: 0.0684, ceiling: false}]},
                        "married": { stan_Ded: 14200, brackets: [{rate: 0.0246, ceiling: 6600}, { rate: 0.0351, ceiling: 39990}, { rate: 0.0501, ceiling: 64430}, {rate: 0.0684, ceiling: false}]}
                    },
                    "Nevada": {
                        "single": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 500}]},
                        "married": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 1000}]}},
                    "New Hampshire": {
                        "single": {stan_Ded: 0, brackets: [{rate: 0.05, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 0, brackets: [{rate: 0.05, ceiling: "Flat Tax"}]}
                    },
                    "New Jersey": {
                        "single": { stan_Ded: 0, brackets: [{rate: 0.014, ceiling: 20000}, {rate: 0.0175, ceiling: 35000}, {rate: 0.035, ceiling: 40000}, {rate: 0.0525, ceiling: 75000}, {rate: 0.06370, ceiling: 500000}, {rate: 0.0897, ceiling: 1000000}, { rate: 0.10750, ceiling: false}]},
                        "married": { stan_Ded: 0, brackets: [{rate: 0.014, ceiling: 20000}, {rate: 0.0175, ceiling: 50000}, {rate: 0.0245, ceiling: 70000}, {rate: 0.035, ceiling: 80000}, {rate: 0.0525, ceiling: 150000}, {rate: 0.06370, ceiling: 500000}, {rate: 0.08970, ceiling: 1000000}, { rate: 0.10750, ceiling: false}]}
                    }, 
                    "New Mexico": {
                        "single": { stan_Ded: 12550, brackets: [{rate: 0.017, ceiling: 5500}, { rate: 0.032, ceiling: 11000}, {rate: 0.0470, ceiling: 16000}, {rate: 0.0490, ceiling: 210000}, { rate: 0.059, ceiling: false}]},
                        "married": { stan_Ded: 25100, brackets: [{rate: 0.017, ceiling: 8000}, { rate: 0.032, ceiling: 16000}, {rate: 0.0470, ceiling: 24000}, {rate: 0.0490, ceiling: 315000}, { rate: 0.059, ceiling: false}]}
                    },
                    "New York": {
                        "single": { stan_Ded: 8000, brackets: [{rate: 0.04, ceiling: 8500}, { rate: 0.045, ceiling: 11700}, {rate: 0.0525, ceiling: 13900}, {rate: 0.059, ceiling: 21400},  {rate: 0.05970, ceiling: 80650}, {rate: 0.0633, ceiling: 215400}, {rate: 0.0, ceiling: 1077550}, { rate: 0.0882, ceiling: false}]},
                        "married": { stan_Ded: 16050, brackets: [{rate: 0.04, ceiling: 17150}, { rate: 0.045, ceiling: 23600}, {rate: 0.0525, ceiling: 27900}, {rate: 0.059, ceiling: 43000},  {rate: 0.05970, ceiling: 161550}, {rate: 0.0633, ceiling: 323200}, {rate: 0.0, ceiling: 2155350}, { rate: 0.0882, ceiling: false}]}
                    },
                    "North Carolina": {
                        "single": {stan_Ded: 10750, brackets: [{rate: 0.0525, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 21500, brackets: [{rate: 0.0525, ceiling: "Flat Tax"}]}
                    },
                    "North Dakota": {
                        "single": { stan_Ded: 12550, brackets: [{rate: 0.011, ceiling: 40125}, { rate: 0.0204, ceiling: 97150}, {rate: 0.0227, ceiling: 202650}, {rate: 0.0264, ceiling: 440600}, { rate: 0.029, ceiling: false}]},
                        "married": { stan_Ded: 25100, brackets: [{rate: 0.011, ceiling: 67050}, { rate: 0.0204, ceiling: 161950}, {rate: 0.0227, ceiling: 246700}, {rate: 0.0264, ceiling: 440600}, { rate: 0.029, ceiling: false}]}
                    },
                    "Ohio": {
                        "single": { stan_Ded: 0, brackets: [{rate: 0.0, ceiling: 22150}, {rate: 0.02850, ceiling: 44250}, {rate: 0.03326, ceiling: 88450}, {rate: 0.03802, ceiling: 110650}, {rate: 0.04413, ceiling: 221300}, { rate: 0.04797, ceiling: false}]},
                        "married": { stan_Ded: 0, brackets: [{rate: 0.0, ceiling: 22150}, {rate: 0.02850, ceiling: 44250}, {rate: 0.03326, ceiling: 88450}, {rate: 0.03802, ceiling: 110650}, {rate: 0.04413, ceiling: 221300}, { rate: 0.04797, ceiling: false}]}
                    },
                    "Oklahoma": {
                        "single": { stan_Ded: 6350, brackets: [{rate: 0.005, ceiling: 1000}, {rate: 0.01, ceiling: 2500}, {rate: 0.02, ceiling: 3750}, {rate: 0.03, ceiling: 4900}, {rate: 0.04, ceiling: 7200}, {rate: 0.05, ceiling: false}]},
                        "married": { stan_Ded: 12700, brackets: [{rate: 0.005, ceiling: 2000}, {rate: 0.01, ceiling: 5000}, {rate: 0.02, ceiling: 7500}, {rate: 0.03, ceiling: 9800}, {rate: 0.04, ceiling: 12200}, {rate: 0.05, ceiling: false}]}
                    },
                    "Oregon": {
                        "single": { stan_Ded: 2315, brackets: [{rate: 0.0475, ceiling: 3650}, {rate: 0.0675, ceiling: 9200}, {rate: 0.0875, ceiling: 125000}, { rate: 0.099, ceiling: false}]},
                        "married": { stan_Ded: 4630, brackets: [{rate: 0.0475, ceiling: 7300}, {rate: 0.0675, ceiling: 18400}, {rate: 0.0875, ceiling: 250000}, { rate: 0.099, ceiling: false}]}
                    },
                    "Pennsylvania": {
                        "single": {stan_Ded: 0, brackets: [{rate: 0.0307, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 0, brackets: [{rate: 0.0307, ceiling: "Flat Tax"}]}
                    },
                    "Rhode Island": {
                        "single": { stan_Ded: 9050, brackets: [{rate: 0.0375, ceiling: 66200}, { rate: 0.0475, ceiling: 150550}, { rate: 0.0599, ceiling: false}]},
                        "married": { stan_Ded: 18100, brackets: [{rate: 0.0375, ceiling: 66200}, { rate: 0.0475, ceiling: 150550}, { rate: 0.0599, ceiling: false}]}
                    },
                    "South Carolina": {
                        "single": { stan_Ded: 12550, brackets: [{rate: 0.0, ceiling: 3070}, {rate: 0.03, ceiling: 6160}, {rate: 0.04, ceiling: 9230}, {rate: 0.05, ceiling: 12310}, {rate: 0.06, ceiling: 15400}, {rate: 0.07, ceiling: false}]},
                        "married": { stan_Ded: 25100, brackets: [{rate: 0.0, ceiling: 3070}, {rate: 0.03, ceiling: 6160}, {rate: 0.04, ceiling: 9230}, {rate: 0.05, ceiling: 12310}, {rate: 0.06, ceiling: 15400}, {rate: 0.07, ceiling: false}]}
                    },
                    "South Dakota": {
                        "single": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 500}]},
                        "married": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 1000}]}},
                    "Tennessee": {
                        "single": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 500}]},
                        "married": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 1000}]}},
                    "Texas": {
                        "single": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 500}]},
                        "married": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 1000}]}},
                    "Utah": {
                        "single": {stan_Ded: 0, brackets: [{rate: 0.0495, ceiling: "Flat Tax"}]},
                        "married": {stan_Ded: 0, brackets: [{rate: 0.0495, ceiling: "Flat Tax"}]}
                    },
                    "Vermont": {
                        "single": { stan_Ded: 6250, brackets: [{rate: 0.0335, ceiling: 40350}, {rate: 0.066, ceiling: 97800}, {rate: 0.076, ceiling: 204000}, {rate: 0.0875, ceiling: false}]},
                        "married": { stan_Ded: 12500, brackets: [{rate: 0.0335, ceiling: 67450}, {rate: 0.066, ceiling: 163000}, {rate: 0.076, ceiling: 248350}, {rate: 0.0875, ceiling: false}]}
                    },
                    "Virginia": {
                        "single": { stan_Ded: 4500, brackets: [{rate: 0.02, ceiling: 3000}, { rate: 0.03, ceiling: 5000}, { rate: 0.05, ceiling: 17000}, { rate: 0.0575, ceiling: false}]},
                        "married": { stan_Ded: 9000, brackets: [{rate: 0.02, ceiling: 3000}, { rate: 0.03, ceiling: 5000}, { rate: 0.05, ceiling: 17000}, { rate: 0.0575, ceiling: false}]}
                    },
                    "Washington": {
                        "single": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 500}]},
                        "married": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 1000}]}
                    },
                    "West Virginia": {
                        "single": { stan_Ded: 0, brackets: [{rate: 0.03, ceiling: 10000}, {rate: 0.04, ceiling: 25000}, {rate: 0.045, ceiling: 40000}, {rate: 0.06, ceiling: 60000}, {rate: 0.065, ceiling: false}]},
                        "married": { stan_Ded: 0, brackets: [{rate: 0.03, ceiling: 10000}, {rate: 0.04, ceiling: 25000}, {rate: 0.045, ceiling: 40000}, {rate: 0.06, ceiling: 60000}, {rate: 0.065, ceiling: false}]}
                    },
                    "Wisconsin": {
                        "single": { stan_Ded: 11050, brackets: [{rate: 0.0354, ceiling: 12120}, {rate: 0.0465, ceiling: 24250}, {rate: 0.0627, ceiling: 266930}, { rate: 0.0765, ceiling: false}]},
                        "married": { stan_Ded: 20470, brackets: [{rate: 0.0354, ceiling: 16160}, {rate: 0.0465, ceiling: 32330}, {rate: 0.0627, ceiling: 355910}, { rate: 0.0765, ceiling: false}]}
                    },
                    "Wyoming": {
                        "single": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 500}]},
                        "married": { stan_Ded: 0, brackets: [{ rate: 0, ceiling: 1000}]}},
                    "Washington D.C.": {
                        "single": { stan_Ded: 12550, brackets: [{rate: 0.04, ceiling: 10000}, {rate: 0.06, ceiling: 40000}, {rate: 0.065, ceiling: 60000}, {rate: 0.085, ceiling: 350000}, {rate: 0.0875, ceiling: 1000000}, {rate: 0.0895, ceiling: false}]},
                        "married": { stan_Ded: 25100, brackets: [{rate: 0.04, ceiling: 10000}, {rate: 0.06, ceiling: 40000}, {rate: 0.065, ceiling: 60000}, {rate: 0.085, ceiling: 350000}, {rate: 0.0875, ceiling: 1000000}, {rate: 0.0895, ceiling: false}]}
                    }
                },
                initialSet: {
                    expenseNames: ["Housing", "Groceries", "Transportation", "Mobile Phone", "Electricity", "Utilites", "Auto Insurance", "Life Insurance", "Savings - Short Term / Emergency", "Savings - Long Term / Retirement", "Discretionary Funds"],
                    expenseCosts: [1200, 80, 60, 60, 55, 40, 65, 30, 300, 100, 250],
                    expenseFrq: ['Month', 'Week', 'Week', 'Month', 'Month', 'Month', 'Month', 'Month', 'Month', 'Week', 'Month'],
                    expenseFrqNumber: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                },
                loadCounter: 0
            }
        },

        //Mounted Function to Load Initial Data Set
        mounted:function(){
            if (this.loadCounter === 0) {
                this.initializeDataTable(), this.summedCost(), this.loadCounter += 1;
            }
            else {this.loadCounter += 1}
        },
        methods: {
            
            //Add New Expense Row to Expense Table
            addNewExpense(index){
                this.expenses.splice(index, 0, {
                    name: '',
                    amount: 0,
                    frequency: 'Month',
                    freqencyNumber: 1,
                    frqPick(){
                        if (this.frequency === "Week"){return 52}
                        else if (this.frequency === "Month"){return 12}
                        else if (this.frequency === "Every Other Month"){return 6}
                        else if (this.frequency === "Quarter"){return 4}
                        else if (this.frequency === "Biannual"){return 2}
                        else if (this.frequency === "Year"){return 1}
                        else if (this.frequency === "Day"){return 365}
                        else {return 0}},
                    yearlyFrq(){return this.frqPick() * this.freqencyNumber},
                    monthly: 0,
                    annually: 0,
                    monthlyFormatted: 0,
                    annuallyFormatted: 0
                })},

            //Remove Expense Row From Expense Table    
            deleteExpense(index){
                if (this.expenses.length > 1)
                  {this.expenses.splice(index, 1);}
            },

            //Keyboard Button Filter - Numbers Only
            numFilt ($event) {
                //console.log($event.keyCode); //keyCodes value
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
                }
            },
            
            /*getPDF(){
                
                let doc = new jsPDF();
                doc.autoTable({
                    theme: 'striped',
                    head:["State", "Filing", "Employment"],
                    body:[this.sessInfo.state, this.sessInfo.filing, this.sessInfo.employment]
                })
                doc.save("capital_forecast_report.pdf")
                return 4;
            },*/

            //Move Expense Row Up or Down in Table
            move (from, to) {
                this.expenses.splice(to, 0, this.expenses.splice(from, 1)[0]);
                return this;
            },

            //Sums Monthly and Yearly Expense Costs - Calculates 
            summedCost(){
                console.log(`Yearly Freeq - ${this.expenses[0].yearlyFrq}, ${this.expenses[0].yearlyFrq()}`)
                for(let i = 0; i < this.expenses.length; i++){
                  if (this.expenses[i].amount == 0 || this.expenses[i].freqencyNumber == 0){
                      this.expenses[i].monthly = 0; this.expenses[i].annually = 0;
                    }
                  else {this.expenses[i].monthlyFormatted = (this.expenses[i].amount * this.expenses[i].yearlyFrq() / 12).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                        this.expenses[i].annuallyFormatted = (this.expenses[i].amount*this.expenses[i].yearlyFrq()).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                        this.expenses[i].monthly = (this.expenses[i].amount * this.expenses[i].yearlyFrq() / 12);
                        this.expenses[i].annually = (this.expenses[i].amount*this.expenses[i].yearlyFrq());
            }}
                if (((this.calcNetPay(this.sessInfo.annualPay)) - Number(this.expenses.reduce((a, c) => a + c.annually, 0))).toFixed(2) < 0){
                    this.isNegative = true
                }
                else {this.isNegative = false}
                
            },

            dataExpCol(){
                this.$gtag.event('Deep-Data-Tables-Viewed', {
                'event_category': 'D-D-T-V',
                'event_label': 'Expanded Data Viewed',
                'value': 1
            })},

            //The function the Mounted Function Calls to Initialize the Table
            initializeDataTable(){
                this.sessInfo.pay = 48000;
                this.sessInfo.payFrequency = "Year"
                this.calcTotalAnnPay();
                for (let i = 0; i < 10; i++){
                    this.addNewExpense(i)
                }
                for (let i = 0; i < this.initialSet.expenseNames.length; i++){                    
                    this.expenses[i].name = this.initialSet.expenseNames[i];
                    this.expenses[i].amount = this.initialSet.expenseCosts[i];
                    this.expenses[i].frequency = this.initialSet.expenseFrq[i];
                    this.expenses[i].freqencyNumber = this.initialSet.expenseFrqNumber[i];                 
                }
                //this.summedCost();                   
            },

            //Sets the Amount of the Standard Deduction
            standard_Tax_Deduction_Set: function(x){ 
                if (this.sessInfo.tax_Deductions === "Standard Deduction")
                    if (x === 'Single'){
                        this.sessInfo.tax_Deduction = 12400
                        return this.sessInfo.tax_Deduction
                    }
                    else if (x === "Married Filing Jointly"){ 
                        this.sessInfo.tax_Deduction = 24800
                        return this.sessInfo.tax_Deduction
                    }
                    else if (x === "Head of Household"){
                        this.sessInfo.tax_Deduction = 18500
                        return this.sessInfo.tax_Deduction
                    }
                    else {return 12400}
                else {
                    this.sessInfo.tax_Deduction = this.sessInfo.itemizedTaxDed
                    return this.sessInfo.tax_Deduction
                    }    
            },

            //Calculates Total Payroll Deductions Based on Deduction Amounts and Pay Frequency
            aggDeds: function(){
                this.sessInfo.annualTotalDeductions = (this.sessInfo.payrollDeductions * parseInt(this.pDFrqsObj[this.sessInfo.pDFrq]));
                console.log(`${this.sessInfo.annualTotalDeductions}`)
            },
            
            //Take Home Pay Calculator - Converts Entered Pay into Annual Pay Format
            calcTotalAnnPay(){
                this.sessInfo.annualPay = (this.sessInfo.pay * parseInt(this.payIntervals[this.sessInfo.payFrequency]))
            },

            //Set sessInfo.stateFiling to "married" or "single", matching format of state taxes object
            setStateFiling(){
                let x = this.sessInfo.filing;
                if (x === "Married Filing Jointly"){
                    console.log(`Setting filing to married`)
                    this.sessInfo.stateFiling = "married"}
                else {this.sessInfo.stateFiling = "single"}
            },

            //Calculates Total Tax 
            aggTaxCredits: function(){this.sessInfo.totalTaxCredits = (parseFloat(this.sessInfo.fedTaxCredits) + parseFloat(this.sessInfo.stateTaxCredits))},

            //Calculates State Taxes Owed 
            stateTaxAmount(x,y){
                function iterate(item) {console.log(item)}
                    let bracks = y;
                    let arr = []
                    if ( bracks.ceiling === "Flat Tax") {return (x*bracks.rate)}
                    else if (x === 0 || x === undefined || x === null || x < 0 || bracks[0].ceiling === undefined){return 0}
                    else {
                        for(let i = 0; i < bracks.length; i++){
                            if (i === 0){
                                if (x < bracks[0].ceiling){
                                    arr.push(Number(parseFloat(x * bracks[0].rate).toFixed(2)));
                                }
                                else {
                                    arr.push(Number(parseFloat(bracks[0].rate * bracks[0].ceiling).toFixed(2)));
                                }
                            }
                            else if (i > 0 && x < bracks[i-1].ceiling){
                                arr.push(0);
                            }
                            else if (x > bracks[i].ceiling && bracks[i].ceiling != false){
                                arr.push(Number(parseFloat((bracks[i].ceiling - bracks[i-1].ceiling)*bracks[i].rate).toFixed(2)));
                            }
                            else {
                                arr.push(Number(parseFloat((x - bracks[i-1].ceiling) * bracks[i].rate).toFixed(2)));
                            }
                        }
                        arr.forEach(iterate)
                        return arr.reduce((a,c) => a+c)
            }},

            //Calculates Federal Taxes Owed
            fedTaxAmount(x,y){
                function iterate(item) {console.log(item)}
                    let bracks = y;
                    console.log(`bracks[0].ceiling: ${bracks[0].ceiling}`)
                    let arr = []
                    if (x === 0 || x === undefined || x === null || x < 0 || bracks[0].ceiling === undefined){return 0}
                    else {
                        for(let i = 0; i < bracks.length; i++){
                            if (i === 0){
                                if (x < bracks[0].ceiling){
                                    arr.push(Number(parseFloat(x * bracks[0].rate).toFixed(2)));
                                }
                                else {
                                    arr.push(Number(parseFloat(bracks[0].rate * bracks[0].ceiling).toFixed(2)));
                                }
                            }
                            else if (i > 0 && x < bracks[i-1].ceiling){
                                arr.push(0);
                            }
                            else if (x > bracks[i].ceiling && bracks[i].ceiling != false){
                                arr.push(Number(parseFloat((bracks[i].ceiling - bracks[i-1].ceiling)*bracks[i].rate).toFixed(2)));
                            }
                            else {
                                arr.push(Number(parseFloat((x - bracks[i-1].ceiling) * bracks[i].rate).toFixed(2)));
                            }
                        }
                        arr.forEach(iterate)
                        return arr.reduce((a,c) => a+c)
                    }
                },

                //Calculates FICA Taxes - SSN and Medicaid
                ficaTaxAmount(x,y,z){
                    let amt = 0
                    if (y === "Employee" && x <= 142800){
                        console.log("Fica Tax 1 Employee Called")
                        this.sessInfo.ssnAmt = (x * 0.062).toFixed(2);
                        this.sessInfo.ssnPerc = (((this.sessInfo.ssnAmt)/z)*100).toFixed(2);
                        this.sessInfo.medicrAmt = (x * 0.0145).toFixed(2);
                        this.sessInfo.medicrPerc = (((this.sessInfo.medicrAmt)/z)*100).toFixed(2);
                        amt = x*0.0765
                    }
                    else if (y === "Employee" && x > 142800 && x <= 200000){
                        this.sessInfo.ssnAmt = (142800 * 0.062).toFixed(2);
                        this.sessInfo.ssnPerc = (((this.sessInfo.ssnAmt)/z)*100).toFixed(2);
                        this.sessInfo.medicrAmt = (x * 0.0145).toFixed(2);
                        this.sessInfo.medicrPerc = (((this.sessInfo.medicrAmt)/z)*100).toFixed(2);
                        amt = ((142800 * 0.062) + (x * 0.0145))
                    }
                    else if (y === "Employee" && x > 200000) {
                        this.sessInfo.ssnAmt = (142800 * 0.062).toFixed(2);
                        this.sessInfo.ssnPerc = (((this.sessInfo.ssnAmt)/z)*100).toFixed(2);
                        this.sessInfo.medicrAmt = (200000 * 0.0145) + ((x-200000) * (0.0145+ 0.0090));
                        this.sessInfo.medicrPerc = (((this.sessInfo.medicrAmt)/z)*100).toFixed(2);
                        amt = ((142800 * 0.062) + (200000 * 0.0145) + ((x-200000) * (0.0145+ 0.0090)))
                    }
                    else if (y === "Self-Employed" && x <= 142800){ 
                        console.log("Fica Tax 1 Self-Employed Called")
                        this.sessInfo.ssnAmt = (x * 0.124).toFixed(2);
                        this.sessInfo.ssnPerc = (((this.sessInfo.ssnAmt)/z)*100).toFixed(2);
                        this.sessInfo.medicrAmt = (x * 0.029).toFixed(2);
                        this.sessInfo.medicrPerc = (((this.sessInfo.medicrAmt)/z)*100).toFixed(2);
                        amt = x * 0.153
                    }
                    else if (y === "Self-Employed" && x > 142800 && x <= 200000){ 
                        this.sessInfo.ssnAmt = (142800 * 0.124).toFixed(2);
                        this.sessInfo.ssnPerc = (((this.sessInfo.ssnAmt)/z)*100).toFixed(2);
                        this.sessInfo.medicrAmt = (x * 0.029).toFixed(2);  
                        this.sessInfo.medicrPerc = (((this.sessInfo.medicrAmt)/z)*100).toFixed(2);                      
                        amt = ( (142800 * 0.124) + (x * 0.029))
                    }
                    else if (y === "Self-Employed" && x > 200000) {
                        this.sessInfo.ssnAmt = (142800 * 0.124).toFixed(2);
                        this.sessInfo.ssnPerc = (((this.sessInfo.ssnAmt)/z)*100).toFixed(2);
                        this.sessInfo.medicrAmt = ((200000 * 0.029) + ((x-200000) * (0.029+ 0.0090))).toFixed(2);
                        this.sessInfo.medicrPerc = (((this.sessInfo.medicrAmt)/z)*100).toFixed(2);
                        amt = ((142800 * 0.124) + (200000 * 0.029) + ((x-200000) * (0.029+ 0.0090))) 
                    }
                    else {amt = 0;}
                    return Math.ceil(amt);
                },

                //Calculates FICA Taxes for Entered Gross Income
                netFicaTaxAmount(x,y){
                    let amt = 0
                    if (y === "Employee" && x <= 142800){
                        amt = x*0.0765
                    }
                    else if (y === "Employee" && x > 142800 && x <= 200000){
                        amt = ((142800 * 0.062) + (x * 0.0145))
                    }
                    else if (y === "Employee" && x > 200000) {
                        amt = ((142800 * 0.062) + (200000 * 0.0145) + ((x-200000) * (0.0145+ 0.0090)))
                    }
                    else if (y === "Self-Employed" && x <= 142800){ 
                        amt = x * 0.153
                    }
                    else if (y === "Self-Employed" && x > 142800 && x <= 200000){                    
                        amt = ( (142800 * 0.124) + (x * 0.029))
                    }
                    else if (y === "Self-Employed" && x > 200000) {
                        amt = ((142800 * 0.124) + (200000 * 0.029) + ((x-200000) * (0.029+ 0.0090))) 
                    }
                    else {amt = 0;}
                    return Math.ceil(amt);
                },

                //Calculates Take Home Pay - Given a "Gross" Amount
                calcTakeHome(amt){

                    //Declare Adjusted Gross Income Guess - Gross Amount minus Tax-Deffered Retirement Savings
                    let adjustedGuess = amt * (1 - (this.sessInfo.retContibs/100));

                    //Log to Console Federal Tax Deduction and 
                    console.log(`Tax Deduction: ${parseInt(this.sessInfo.tax_Deduction)} ; Income Adjustments: ${parseInt(this.sessInfo.income_Adjustments)}`)

                    //Calculates Retirement Contribution Dollar Amount
                    this.sessInfo.retContrbAmt = (amt - adjustedGuess).toFixed(2);

                    //Declare Federal Taxable Income Amount - Console Logs Check Values Passing Through The Function
                    let fedTaxableIncome = Number(adjustedGuess - parseInt(this.sessInfo.tax_Deduction) - parseInt(this.sessInfo.income_Adjustments)).toFixed(2);
                    console.log(`Amt: ${amt} ; fedTaxableIncome: ${fedTaxableIncome}`)
                    console.log(`Fed Filing: ${this.federalTaxBrackets[this.sessInfo.filing]} ; Fed Tax Credits: ${parseInt(this.sessInfo.fedTaxCredits)}`)

                    //Calls Federal Tax Calc Function, Returns Dollar Amt Fed Taxes Owed
                    let fedTax = this.fedTaxAmount(fedTaxableIncome, this.federalTaxBrackets[this.sessInfo.filing]) - parseInt(this.sessInfo.fedTaxCredits);
                    this.fedTaxesDue = Number(fedTax);
                    this.fedTaxesDueTH = Number(fedTax);

                    //Declare State Taxable Income Amount - Console Logs Check Values Being Passed Through The Function
                    let stateTaxableIncome = Number(adjustedGuess - parseInt(this.st_Tx[this.sessInfo.state][this.sessInfo.stateFiling].stan_Ded)).toFixed(2);
                    console.log(`State Filing: ${this.st_Tx[this.sessInfo.state][this.sessInfo.stateFiling].brackets} ; State Tax Credits: ${parseInt(this.sessInfo.stateTaxCredits)}`)
                    
                    //Calls Sate Tax Calc Function, Returns Dollar Amt State Taxes Owed
                    let stateTax = this.stateTaxAmount(stateTaxableIncome, this.st_Tx[this.sessInfo.state][this.sessInfo.stateFiling].brackets) - parseInt(this.sessInfo.stateTaxCredits);
                    this.stateTaxesDue = stateTax;
                    this.stateTaxesDueTH = stateTax;
                    
                    //Calculates Local Tax Bill Due & Effective Percent of Local Tax on Gross Wages
                    let localTax = Math.ceil(adjustedGuess * parseInt(this.sessInfo.local_Income_Tax_Rate)/100);
                    this.localTaxesDue = localTax; 
                    this.localTaxesDueTH = localTax; 
                    this.localTaxEff = ((localTax/adjustedGuess)*100).toFixed(2); 
                    this.localTaxEffTH = ((localTax/adjustedGuess)*100).toFixed(2);

                    //Calculates FICA Taxes Due - Calls FICA Calc Function
                    let ficaTax = this.ficaTaxAmount(adjustedGuess, this.sessInfo.employment_Type, amt);
                    this.ficaTaxesDue = ficaTax;
                    this.ficaTaxesDueTH = ficaTax;
                    
                    //Console Logs Calculated Taxes and Deductions
                    console.log(`Fed Tax: ${fedTax}, State Tax: ${stateTax}, Local Tax: ${localTax}, FICA Tax: ${ficaTax}, Payroll Deductions: ${this.sessInfo.annualTotalDeductions}`)

                    //Calculates Take Home (Clearing) Pay
                    let takeHome = Math.ceil(adjustedGuess - fedTax - stateTax - localTax - ficaTax - this.sessInfo.annualTotalDeductions);

                    //End of Function, Returns Take Home Pay
                    return takeHome;
                    },

                calcNetPay(x){
                    let adjGross = x * (1 - (this.sessInfo.retContibs/100));

                    let fedTaxableIncome = Number(adjGross - parseInt(this.sessInfo.tax_Deduction) - parseInt(this.sessInfo.income_Adjustments)).toFixed(2);
                    let fedTax = this.fedTaxAmount(fedTaxableIncome, this.federalTaxBrackets[this.sessInfo.filing]) - parseInt(this.sessInfo.fedTaxCredits);

                    let stateTaxableIncome = Number(adjGross - parseInt(this.st_Tx[this.sessInfo.state][this.sessInfo.stateFiling].stan_Ded)).toFixed(2);
                    let stateTax = this.stateTaxAmount(stateTaxableIncome, this.st_Tx[this.sessInfo.state][this.sessInfo.stateFiling].brackets) - parseInt(this.sessInfo.stateTaxCredits);

                    let localTax = Math.ceil(adjGross * parseInt(this.sessInfo.local_Income_Tax_Rate)/100);

                    let ficaTax = this.netFicaTaxAmount(adjGross, this.sessInfo.employment_Type);

                    let takeHome = Math.ceil(adjGross - fedTax - stateTax - localTax - ficaTax - this.sessInfo.annualTotalDeductions);

                    return takeHome;
                },

                //Calibration Function - Takes an Amount as a Dummy "Gross Income", Calculates Take Home Pay of that Gross, Compares This Take Home to 
                calibrateGrossDemand(amt, annSum){
                    
                    //Declare Variable Equal to "amt". Declare empty Gross Income Demand Variable. "guessAmt" To Be Used In Recursive Function Call
                    let guessAmt = amt;
                    let grossDemand;
                    
                    //Calculates Take Home Pay Using Variable "amt"
                    let takeHomeProj = Math.ceil(this.calcTakeHome(amt));
                    console.log(`Take Home Projection: ${takeHomeProj}`);

                    //IF "amt" equals zero condition handler
                    if (amt === 0){return 0}

                    //If Take Home Pay is Within 1% of Annual Expense Sum "annSum" variable sets "grossDemand" to the "amt" Variable 
                    else if (Math.abs((annSum - takeHomeProj)/takeHomeProj) < 0.001) {
                        console.log(`Gross Demand: ${Math.ceil(guessAmt)}`);
                        grossDemand = guessAmt;		
                    }

                    //If Take Home Pay Less Than Annual Expense Sum "annSum" Condition - Calibrates "guessAmt" Upwards and Calls The Parent Function (Recursion) with the Adjusted "guessAmt" and the Same Annual Sum "annSum"
                    else if (takeHomeProj < annSum && annSum < 138000) {
                    let perDiff = Math.abs((annSum - takeHomeProj)/takeHomeProj)
                    console.log(`Annual Sum: ${annSum}, Take Home Projection: ${takeHomeProj}, Percentage Different: ${perDiff}`)
                    switch (true){
                        case (perDiff >= 0.3):
                            guessAmt *= (1+0.4);
                            break; 
                        case (perDiff >= 0.2):
                            guessAmt *= (1+0.25);
                            break;
                        case (perDiff >= 0.1):
                            guessAmt *= (1+0.1);
                            break;
                        case (perDiff >= 0.05):
                            guessAmt *= (1+0.05);
                            break;
                        case (perDiff >= 0.01):
                            guessAmt *= (1+0.01);
                            break;
                        case (perDiff < 0.01):
                            guessAmt *= (1+0.0005);
                            break;
                        }
                        console.log("TakeHome Projection Less Than Annual Sum Function Run");
                        return this.calibrateGrossDemand(guessAmt, annSum);	
                        }
                        
                    //Remaining Calibration Condition, Take Home Pay Greater Than Annual Sum, Calibrates "guessAmt" Downward and Calls The Parent Function (Recursion) with the Adjusted "guessAmt" and the Same Annual Sum "annSum"	
                    else if (takeHomeProj > annSum && annSum < 138000) {
                        let perDiff = Math.abs((annSum - takeHomeProj)/takeHomeProj)
                        console.log(`Annual Sum: ${annSum}, Take Home Projection: ${takeHomeProj}, Percentage Different: ${perDiff}`)
                        switch (true){
                            case (perDiff >= 0.3):
                                guessAmt *= (1-0.4);
                                break;
                            case (perDiff >= 0.2):
                                guessAmt *= (1-0.25);
                                break;
                            case (perDiff >= 0.1):
                                guessAmt *= (1-0.01);
                                break;
                            case (perDiff >= 0.05):
                                guessAmt *= (1-0.005);
                                break;
                            case (perDiff >= 0.01):
                                guessAmt *= (1-0.001);
                                break;
                            case (perDiff < 0.01):
                                guessAmt *= (1-0.0005);
                                break;
                        }
                            console.log("TakeHome Projection More Than Annual Sum Function Run");
                            return this.calibrateGrossDemand(guessAmt, annSum);
                        }

                        else if ((takeHomeProj < annSum && annSum > 138000)){
                            let perDiff = Math.abs((annSum - takeHomeProj)/takeHomeProj)
                        console.log(`Annual Sum: ${annSum}, Take Home Projection: ${takeHomeProj}, Percentage Different: ${perDiff}`)
                            switch (true){
                                case (perDiff >= 0.3):
                                    guessAmt *= (1+0.04);
                                    break;
                                case (perDiff >= 0.2):
                                    guessAmt *= (1+0.025);
                                    break;
                                case (perDiff >= 0.1):
                                    guessAmt *= (1+0.001);
                                    break;
                                case (perDiff >= 0.05):
                                    guessAmt *= (1+0.0005);
                                    break;
                                case (perDiff >= 0.01):
                                    guessAmt *= (1+0.0001);
                                    break;
                                case (perDiff < 0.01):
                                    guessAmt *= (1+0.00005);
                                    break;
                        }
                            console.log("TakeHome Projection More Than Annual Sum Function Run");
                            return this.calibrateGrossDemand(guessAmt, annSum);
                        }

                        else {
                            let perDiff = Math.abs((annSum - takeHomeProj)/takeHomeProj)
                            console.log(`Annual Sum: ${annSum}, Take Home Projection: ${takeHomeProj}, Percentage Different: ${perDiff}`)
                            switch (true){
                                case (perDiff >= 0.3):
                                    guessAmt *= (1-0.04);
                                    break;
                                case (perDiff >= 0.2):
                                    guessAmt *= (1-0.025);
                                    break;
                                case (perDiff >= 0.1):
                                    guessAmt *= (1-0.001);
                                    break;
                                case (perDiff >= 0.05):
                                    guessAmt *= (1-0.0005);
                                    break;
                                case (perDiff >= 0.01):
                                    guessAmt *= (1-0.0001);
                                    break;
                                case (perDiff < 0.01):
                                    guessAmt *= (1-0.00005);
                                    break;
                            }
                            console.log("TakeHome Projection More Than Annual Sum Function Run");
                            return this.calibrateGrossDemand(guessAmt, annSum);
                        }

                        console.log(`End of calibrateGrossDemand Function, returning grossDemand = ${grossDemand}`)
                        return Math.ceil(grossDemand);
                    }
        },  
        computed: {

            //Returns State Currently Selected
            currentState(){
                return this.sessInfo.state.toString().toLowerCase();
            },

            //Returns the State Standard Deduction Amount Based on Filing Status
            returnStateDeduction(){
                return this.st_Tx[this.sessInfo.state][this.sessInfo.stateFiling].stan_Ded;
            },

            //Returns the monthly total for each expense
            calcMonthlyTotal(){
                return Number(this.expenses.reduce((a, c) => a + c.monthly, 0)).toFixed(2);
            },
            
            //Returns the monthly total formatted for each expense
            formatMonthlyTotal(){
                return Number(this.expenses.reduce((a, c) => a + c.monthly, 0)).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            },
            
            //Returns the annual / yearly total for each expense
            calcAnnualTotal(){
            return Number(this.expenses.reduce((a, c) => a + c.annually, 0)).toFixed(2);
            }, 

            //Returns the annual total formatted for each expense
            formatAnnualTotal(){
            return Number(this.expenses.reduce((a, c) => a + c.annually, 0)).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","); 
            },
            
            //Produces the a Value Based on the Total of the Expense List to Begin Calculating Take Home Pay Projections Which are Compared to and Calibrated Against the Expense Demand Total
            grossGuess(){
                if (this.calcAnnualTotal === 0 || this.calcAnnualTotal === null || this.calcAnnualTotal === undefined){
                    return 0
                }
                else if (this.calcAnnualTotal >= 80000 ) {
                    return Math.ceil(this.calcAnnualTotal * (1.5))}
                else {
                    return Math.ceil(this.calcAnnualTotal * (1.25))}
                },

            //Returns The Federal Tax Brackets - Progressive Rates - Used in Calculator    
            fedTaxBracket(){return this.federalTaxBrackets[this.sessInfo.filing]},

            //Returns State Tax Brackets
            stateTaxBracket(){return this.st_Tx[this.sessInfo.state][this.sessInfo.stateFiling].brackets},

            //Returns Gross Demand 
            calibrator(){
                return this.calibrateGrossDemand(this.grossGuess, this.calcAnnualTotal)
            },

            //Returns Gross Demand Per Year Formatted
            calibratorSalary(){return Number(2*this.calibrator/(2)).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            //Returns Gross Demand Per Month Formatted
            calibrateMonthly(){return Number(this.calibrator/(12)).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            //Returns Gross Demand Per Hour Formatted
            calibratorHourly(){return Number(this.calibrator/(52*40)).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            //Returns the State Taxes Amt Owed and The Same Value Formatted 
            stateTaxesSum(){return this.stateTaxesDue},
            stateTaxesSumFmt(){return this.stateTaxesDue.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},
            
            //Returns State Taxes as Percent of Capital Demand
            stateTaxesPercent(){
                let x = this.stateTaxesDue;
                let y = this.calibrator;
                let z = x/y;
                let zR = (z * 100).toFixed(2);
                return zR;
            },

            //Returns the Federal Taxes Amt Owed and The Same Value Formatted 
            fedTaxesSum(){return this.fedTaxesDue},
            fedTaxesSumFmt(){return this.fedTaxesDue.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            //Returns Federal Taxes as Percent of Capital Demand
            fedTaxesPercent(){
                let x = this.fedTaxesDue;
                let y = this.calibrator;
                let z = x/y;
                let zR = (z * 100).toFixed(2);
                return zR;
            },

            //Returns Medicare Tax Amount Owed Formatted
            medicareTaxAmtFmt(){return this.sessInfo.medicrAmt.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            //Returns Social Security Tax Amount Owed formatted
            ssnTaxAmtFmt(){return this.sessInfo.ssnAmt.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            //Returns Local Taxes Due Formatted as Percent
            localTaxesSumFmt(){return this.localTaxesDue.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            //Returns Standard Deduction Given Current Filing Status
            curStndrdDed(){return this.standardDeductions[this.sessInfo.filing]},

            //Returns Take Home Pay From a Given Annual Gross Pay
            takeHomeForecast(){return (this.calcNetPay(this.sessInfo.annualPay)).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            takeHomeForecastMonthly(){return ((this.calcNetPay(this.sessInfo.annualPay))/12).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            returnAnnualPay(){ return (this.sessInfo.annualPay).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            returnMonthlyPay(){ return (this.sessInfo.annualPay / 12).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")},

            diffSalaryAnnual(){
                let x = ((this.calcNetPay(this.sessInfo.annualPay)) - Number(this.expenses.reduce((a, c) => a + c.annually, 0))).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                if (x < 0) {this.isNegative = true}
                else (this.isNegative = false)
                return x;                
                },

            diffSalaryMonth(){
                let x = (((this.calcNetPay(this.sessInfo.annualPay)) - Number(this.expenses.reduce((a, c) => a + c.annually, 0)))/12).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                if (x < 0) {this.isNegative = true}
                else (this.isNegative = false)
                return x; 
                },

            diffGCDAnnual(){
                let x = (Number(this.sessInfo.annualPay) - Number(this.calibrator)).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                if (x < 0) {this.isNegative = true}
                else (this.isNegative = false)
                return x; 
                },

            diffGCDMonth(){
                let x = ((Number(this.sessInfo.annualPay) - Number(this.calibrator))/12).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                if (x < 0) {this.isNegative = true}
                else (this.isNegative = false)
                return x; 
                },
                            

            doughnutExpenseDataSetter(){
                let x = [];
                for (let i = 0; i < this.expenses.length; i++){
                    x[i] = {
                       expenseName: (this.expenses[i].name),
                       annualCost: (this.expenses[i].annually),
                       annPercent: ((this.expenses[i].annually / this.calibratorSalary)*100)
                }}
                x.sort(function(a,b){return a.annualCost - b.annualCost})
                
                return x;
            }
        }
    }
</script>


<style>


.containerCalc{
    background: rgb(255, 255, 255);
    display: grid;
    padding-left: .25em;
    padding-right: .25em;
    grid-row-gap: 3px;
    grid-template-rows: minmax(0.1fr, 0.8fr) minmax(0.2fr 1fr); 
    grid-template-areas: 
    "sessInfoDisplay"
    "calcsData"
}

.sessInfoContainer{
    grid-area: sessInfoDisplay;
    margin: auto;
    width: 100% ;
    background: rgb(255, 255, 255);
    border-radius: 1em;
}

.negative{
    color: rgb(199, 6, 6)
}


.serviceTitle{
    font-size: 1.1em;
    color: rgb(0, 0, 0);
    text-align: center;
}

.sessInfoDisplay > h3 {
    margin: 0 auto;
    padding: .25em;
    border-radius: 0px 4px 0px 0px;
    text-align: center;
    font-size: 1.1em;
}

.sessInfoTable{
    width: auto;
    table-layout: fixed;
    color: #000;
    margin: 0 auto;
    -webkit-box-shadow: -6px 6px 11px 4px #060b2a; 
    box-shadow: -6px 6px 11px 4px #060b2a;
    border-radius: 4px 4px 4px 4px;
    padding-top: 5px;
    padding-bottom: 12px;
    background: rgb(255, 255, 255);
    padding: 0.25em;
    margin-bottom: 10px;
    font-size: 14px;
    max-width: 27vw;
}

.sessInfoTable > tr > td{
    padding: 0.625em;
    text-align: center; 
    vertical-align: middle;
    margin: auto;
    font-size: 14px;
    width: auto;
    word-wrap: break-word;    
}

.sessInfoTable > tr > th{
    text-transform: bold;
    text-align: center; 
    vertical-align: middle;
    margin: auto;
    font-size: 14px;
    width: auto;
    white-space: nowrap;   
}

.sessInfoTable > tr > td > select {
    /*width: minmax(40px 105px);*/
    padding: 0.125em;
    width: 70px;
}


#userInfoTable > tr > td > select {
    width: 95px;
} 

#userInfoTable > tr > td > input {
    width: 95px;
} 

#userInfoTable{
    max-width: 40vw;
}

#sessInfoHomeTable {
    max-width: 40vw;
}


.calcsAndData{
    grid-area: calcsData;
    position: relative;
}

#capitalDemandContainer{
    display: grid;
    grid-template-rows: min-content;
    grid-row-gap: 4px;
    grid-template-areas: "fC"
    "fD";
    background: #197C17;
    border-radius: 1em;
    
    /* max-height: 55vh; */
}

#downloadButton {
    text-align: center;
}

#forecastCalc{
    grid-area: fC;
    overflow-x: hidden;
    max-height: 45vh;
    overflow-y: auto;
    width: auto;
    margin: 0 auto;
    padding-bottom: 12px;
    margin-bottom: 12px;
    padding-left: 1em;
    padding-right: 1.8em;
}

#forecastCalc > h3 {
    color: white;
    padding: .25em;
    font-size: 1.1em;
}

#firstTable {
    max-width: 40vw;
}

#expTable{
    display: table;
    /* margin-left: 18px; */
    margin: 0 auto;
    background: rgb(255, 255, 255);
    -webkit-box-shadow: -6px 6px 11px 4px #060b2a; 
    box-shadow: -6px 6px 11px 4px #060b2a;
    border-collapse: collapse;
    padding: 1.25em;
    width: auto;
    border-radius: 4px 4px 4px 4px;
}

#expTable > tbody > tr > td{
    white-space: nowrap;
    padding: .25em;
    text-align: center; 
    vertical-align: middle;
    margin: auto 0;
    font-size: 14px; 
}

#expTable > tbody > tr > td > .controlButton{
    max-width: 25px;
}

#expTable > tbody > tr > td > input{
    width: min(60px auto);
    max-width: 60px;
}

#expTable > tbody > tr > td > select{
    width: min(60px auto);
    max-width: 60px;
}

#expTable > tbody > tr > th{
    text-align: center;
    font-size: 14px;
    color: rgb(255, 255, 255);
    padding: .25em;
    white-space: nowrap;
    vertical-align: middle;
    margin: auto 0;
}

#expTable > tbody > tr > th > .controlButton{
    max-width: 25px;
}

table > #expTable > .sessInfoTable {
    margin: 0 auto;
}

#dataMax{
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-template-areas: "table1 table2"
    "table3 table4";
}

#inputHeaders{
    background: #197C17;
    border-radius: 5px 5px 0px 0px;
}

#billDes {
    width: 80px;
}

.expenseRows{
    background-color: white;
}

.expenseRows.even{
    background-color: #daebee;
}

button{
    width: auto;
    display: inline;
}

.disabled {
  visibility: hidden;
  height: 0px;
}

#forecastData {
    padding: 0.625em;
    background: rgb(255, 255, 255)
}

#takeHomeEstimator{
    margin: 0 auto;
    width: 100%;
    text-align: center;
    color: white;
    background: #197C17;
    border-radius: 5px 5px 0px 0px;
}

#takeHomePara{
    margin: 0 auto;
}

#takeHomeData {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-template-areas: "table5 table6"
    "table7 table8";
    padding: 0.625em;
}


@media (max-width: 1000px) {

.sessInfoTable > tr > td > select {
    /*width: minmax(40px 105px);*/
    padding: 0.125em;
    width: 40px;
}

#userInfoTable > tr > td > select {
    width: 95px;
} 

#userInfoTable > tr > td > input {
    width: 95px;
} 

#takeHomeData {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    grid-template-areas: "table5" "table6" "table7" "table8";
    padding: 0.0625em;
    width: fit-content;
}

#dataMax{
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    grid-template-areas: "table1" "table2" "table3" "table4";
    width: fit-content;
}

#expTable{
    transform: scale(0.625);
    margin: inherit;
    align-self: left;
}

#forecastCalc{
    margin: 0px 20px 5px -26px;
    width: fit-content;
}

#forecastCalc > h3 {
    margin: 0 auto;
}

.sessInfoTable{
    transform: scale(0.75);
    margin: inherit;
}

.sessInfoContainer{
    grid-area: sessInfoDisplay;
    margin: auto;
    width: 100%;
    max-width: 96vw;
    border-radius: 1em;
}

h3 {
    margin: inherit;
    text-align: left;
}

}

/*
#forecastData, .table-Header{
    background-color: #085225
}

#forecastData, .table-Content{
    height: minmax(50px 500px);
    border: 1px solid #085225;
}

#forecastData, td{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    min-width: 26px;
    padding: 10px;
    vertical-align: middle;
    text-align: left;
    font-size: 12px;
    color: black;
}

ul{
    background-color: white;
    -webkit-box-shadow: -6px 6px 11px 4px #BFBFBF; 
    box-shadow: -6px 6px 11px 4px #BFBFBF;
}

li{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    min-width: 26px;
    font-size: 1em;;
}

#expTable > tbody > tr > td{
    padding: .125em;    
    font-size: 10px; 
}

#expTable > tbody > tr > td > .controlButton{
    max-width: 15px;
}

#expTable > tbody > tr > td > input{
    width: min(60px auto);
    max-width: 35px;
}

#expTable > tbody > tr > td > select{
    width: min(60px auto);
    max-width: 35px;
}

#expTable > tbody > tr > th{
    font-size: 10px;
    padding: .125em;
}

#expTable > tbody > tr > th > .controlButton{
    max-width: 15px;
}

*/

/*


.container{
    background: rgb(1, 1, 19);
    display: grid;
    grid-row-gap: 3px;
    grid-template-rows: minmax(0.1fr, 0.8fr) minmax(0.2fr 1fr); 
    grid-template-areas: 
    "sessInfoDisplay"
    "calcsData"
}

.sessInfoContainer{
    grid-area: sessInfoDisplay;
    margin: auto;
    width: 100% ;
    background: rgb(112, 112, 112);
    border-radius: 0px 4px 0px 0px;
}

.sessInfoDisplay{
    color: rgb(255, 255, 255);
    
}

.sessInfoDisplay > h3 {
    margin: 0 auto;
    padding: .25em;
    border-radius: 0px 4px 0px 0px;
    text-align: center;
    font-size: smaller;
}

.sessInfoTable{
    width: auto;
    table-layout: fixed;
    color: #000;
    margin: 0 auto;
    -webkit-box-shadow: -6px 6px 11px 4px #060b2a; 
    box-shadow: -6px 6px 11px 4px #060b2a;
    border-radius: 4px 4px 4px 4px;
    padding-top: 5px;
    padding-bottom: 12px;
    background: rgb(255, 255, 255);
    padding: 0.25em;
    margin-bottom: 10px;
    font-size: 14px;
}

.sessInfoTable > tr > td{
    padding: 0.625em;
    text-align: center; 
    vertical-align: middle;
    margin: auto;
    color: black;
    font-size: 14px;
    width: auto;
    white-space: nowrap;    
}

.sessInfoTable > tr > th{
    text-transform: bold;
    text-align: center; 
    vertical-align: middle;
    margin: auto;
    font-size: 14px;
    width: auto;
    white-space: nowrap;   
}

.sessInfoTable > tr > td > select {
    
    padding: 0.125em;
    width: 70px;
}


#userInfoTable > tr > td > select {
    width: 95px;
} 

#userInfoTable > tr > td > input {
    width: 95px;
} 


.calcsAndData{
    grid-area: calcsData;
    position: relative;
}

#capitalDemandContainer{
    display: grid;
    grid-template-rows: 60% 40%;
    grid-row-gap: 4px;
    grid-template-areas: "fC"
    "fD";
    background: #197C17;
    
    
}

#forecastCalc{
    grid-area: fC;
    overflow-x: hidden;
    max-height: 45vh;
    overflow-y: auto;
    width: auto;
    margin: 0 auto;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

#forecastCalc > h3 {
    color: white;
    padding: .25em;
    font-size: smaller;
}

#expTable{
    display: table;
    
    margin: 0 auto;
    background: rgb(255, 255, 255);
    -webkit-box-shadow: -6px 6px 11px 4px #060b2a; 
    box-shadow: -6px 6px 11px 4px #060b2a;
    border-collapse: collapse;
    padding: 1.25em;
    width: auto;
    border-radius: 4px 4px 4px 4px;
}

#expTable > tbody > tr > td{
    white-space: nowrap;
    padding: .25em;
    text-align: center; 
    vertical-align: middle;
    margin: auto 0;
    font-size: 14px; 
}

#expTable > tbody > tr > td > .controlButton{
    max-width: 25px;
}

#expTable > tbody > tr > td > input{
    width: min(60px auto);
    max-width: 60px;
}

#expTable > tbody > tr > td > select{
    width: min(60px auto);
    max-width: 60px;
}

#expTable > tbody > tr > th{
    text-align: center;
    font-size: 14px;
    color: rgb(255, 255, 255);
    padding: .25em;
    white-space: nowrap;
    vertical-align: middle;
    margin: auto 0;
}

#expTable > tbody > tr > th > .controlButton{
    max-width: 25px;
}

table > #expTable > .sessInfoTable {
    margin: 0 auto;
}

#dataMax{
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-template-areas: "table1 table2"
    "table3 table4";
}

#inputHeaders{
    background: #197C17;
    border-radius: 5px 5px 0px 0px;
}

#billDes {
    width: 80px;
}

.expenseRows{
    background-color: white;
}

.expenseRows.even{
    background-color: #daebee;
}

button{
    width: auto;
    display: inline;
}

.disabled {
  visibility: hidden;
  height: 0px;
}

#forecastData {
    padding: 0.625em;
    background: rgb(198, 198, 198)
}

#takeHomeEstimator{
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

#takeHomeData {
    background: rgb(198, 198, 198);
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-template-areas: "table5 table6"
    "table7 table8";
    padding: 0.625em;
}


#forecastData, .table-Header{
    background-color: #085225
}

#forecastData, .table-Content{
    height: minmax(50px 500px);
    border: 1px solid #085225;
}

#forecastData, td{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    min-width: 26px;
    padding: 10px;
    vertical-align: middle;
    text-align: left;
    font-size: 12px;
    color: black;
}

ul{
    background-color: white;
    -webkit-box-shadow: -6px 6px 11px 4px #BFBFBF; 
    box-shadow: -6px 6px 11px 4px #BFBFBF;
}

li{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    min-width: 26px;
    font-size: 1em;;
}

#expTable > tbody > tr > td{
    padding: .125em;    
    font-size: 10px; 
}

#expTable > tbody > tr > td > .controlButton{
    max-width: 15px;
}

#expTable > tbody > tr > td > input{
    width: min(60px auto);
    max-width: 35px;
}

#expTable > tbody > tr > td > select{
    width: min(60px auto);
    max-width: 35px;
}

#expTable > tbody > tr > th{
    font-size: 10px;
    padding: .125em;
}

#expTable > tbody > tr > th > .controlButton{
    max-width: 15px;
}



@media (max-width: 1000px) {

.sessInfoTable > tr > td > select {
    padding: 0.125em;
    width: 40px;
}

#userInfoTable > tr > td > select {
    width: 95px;
} 

#userInfoTable > tr > td > input {
    width: 95px;
} 

#takeHomeData {
    background: rgb(198, 198, 198);
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    grid-template-areas: "table5" "table6" "table7" "table8";
    padding: 0.0625em;
    width: fit-content;
}

#dataMax{
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    grid-template-areas: "table1" "table2" "table3" "table4";
    width: fit-content;
}

#expTable{
    transform: scale(0.72);
    margin: inherit;
    align-self: left;
}

#forecastCalc{
    margin: 0px 20px 5px -26px;
    width: fit-content;
}

#forecastCalc > h3 {
    margin: 0 auto;
}

.sessInfoTable{
    transform: scale(0.75);
    margin: inherit;
}

.sessInfoContainer{
    grid-area: sessInfoDisplay;
    margin: auto;
    width: 100%;
    max-width: 96vw;
    background: rgb(112, 112, 112);
    border-radius: 0px 4px 0px 0px;
}

h3 {
    margin: inherit;
    text-align: left;
}

}
*/
</style>
