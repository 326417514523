<template>
    <div class = "container1">

        <h1>{{pageName}}</h1>
            <section class="accordion">
              <input type="checkbox" name="collapse" id="handle1" checked="checked">
              <h2 class="handle">
                <label for="handle1">{{articleTitles[0]}}</label>
              </h2>
              <div class="content">
                <p>
                    This site is here to help people gauge their own expensiveness, figure out how much you are going to cost yourself and then how much you're actually going to need. We want you to think about everything you need, want, or could have use of money for. 
                    The primary tool we've developed for this problem is the Capital Demand Forecaster. Adjust the session's user information to reflect your tax circumstances - state of residency, filing status, deductions, adjustments, and more to try to make as accurate a forecast as possible.
                    Enter the obligations, elections, and protections you want to plan for into the Capital Demand Forecaster's flexible expense table, set the costs and frequencies. Our algorithms go to work from there, totaling your costs and forecasting your capital demand along with a set of related ramification data

                    Our goal here is to get you a good idea of the wage you need to go after to satisify your capital demands, tax obligations, and savings elections.    

                    This forecast is an estimate based on the philosophy that everyone's bills have a gross sum total and that every gross income results in a particual net/ take home income. The program processes data in ways similar to other online budget takehome estimators. Its results are to be used with your own discretion and criticism. It is not making tax or investment advice. Its data returned is only as valuable as the quality of its input data. It is
                </p>
              </div>
            </section>
            <section class="accordion">
              <input type="checkbox" name="collapse2" id="handle2">
              <h2 class="handle">
                <label for="handle2">{{articleTitles[1]}}</label>
              </h2>
              <div class="content">
                  <p>
                Paying rent? Or have a mortgage? Its likely your housing bill is “always” due again. Avoid the
                    stress of a rent or mortgage crunch by setting aside the right portion of your paycheck whenever you are
                    paid. Commonly in the United States, housing is your largest bill and is due once per month. Paychecks
                    typically come weekly or biweekly. A strategy to reduce bill stress and always know you will be able to
                    pay for your roof is fractional amount saving. Divide your housing bill by your number of monthly
                    paychecks and move that amount into a savings account. When the bill comes due, you will have the
                    money ready and waiting.
                    This strategy can be ramped up how ever much you want. Insurance, car payment, and other
                    fixed rate periodic bills can be added to your housing cost and divided the same way to find the larger
                    number you need to save to cover your over all bills.
                    Reduce your recurring expenses as best you can. End subscriptions you don’t utilize, shop your
                    auto and life insurance policies and other bills when possible. Reduce the overall dollars demanded of
                    you, while still maintaining your obligations. Aim to make ends meet at first, then work on building
                    breathing space.
                    </p>
              </div>
            </section>
            <section class="accordion">
              <input type="checkbox" name="collapse2" id="handle3">
              <h2 class="handle">
                <label for="handle3">{{articleTitles[2]}}</label>
              </h2>
              <div class="content">
                <p>
                    Self-protection is as natural an instinct as any. Financial self-protection is easy if you're sitting on a stack, but its also easy if you're not.
                    It is the first componenet of a solid financial base and can be obtained with the affordable, straightforward product discussed in the next paragraph. 
                    Knowing how much your monthly expenses and saving an emergency fund provides you a contingency plan and makes for base resilient. Getting to your demand wage will greatly help here. 

                    Life Insurance – Term life insurance. When you are young you often have high liabilities and low
                    cash wealth. Think education loans and mortgages. Life insurance provides protection to your family
                    from those liabilities. Term life insurance policies are the cheapest monthly policies and typically payout
                    hundreds of thousands of dollars, even millions sometimes. You can set your coverage. Offerings in the
                    range of $30 per month for $250,000 in coverage are common. Ladder is an excellent, economical, no
                    hassle life insurance company. Found out more.
                    The “downside” to term life insurance is that if you outlive your policy, then you receive
                    nothing. Term life insurance is truly a “just in case” product. The benefit is that for a low price you can
                    totally protect your assets, future obligations, and liabilities in the event of an untimely death. A cheaper
                    insurance policy leaves you with more capital for establishing an emergency fund and saving for
                    retirement (or saving for an old age supplemental fund if you don’t think you’ll ever retire).
                    In addition to traditional term life insurance, for those that would like a pay out if they outlive
                    their insurance term, there are return of premium life insurance. These policies cost more per month
                    compared to term life insurance for the same coverage, but they will return all the premiums you paid
                    over the years if you outlive the policy term, some even return a portion of interest accrued.
                    Avoid whole life insurance, it is overly expensive, it often underinsures and doesn’t payback till
                    the “whole” term has ended, often only when the insured person turns 100 years old. Yes you can
                    “cash” in the policy early, but based on how the payout value accrues, these are not very valuable till
                    the “term” is nearing its end (and again the “term” ends when the policy holder turns 100). Whole life
                    insurance is often marketed as a way to build a nest egg while protecting yourself as the policy must
                    payout, either when the policy holder dies or when they turn 100 years old. This is a bad strategy.
                    Insurance is for protection, not for growing your wealth or building your nest egg. In most cases if an
                    individual took the difference in premium costs between whole life insurance and term life insurance for
                    the same coverage, and invested in a Roth IRA comprised of index funds, they would end up wealthier.
                    
                    Emergency Fund – As recent world events have reinforced, it is very important to have money
                    saved for rainy days and unexpected cash flow interruptions. A common recommendation is to build up
                    an emergency fund and to set it aside somewhere you cannot spend it. The amount is in the end up to
                    you, but it is recommended to have a minimum of three months expenses saved. Reference the budget
                    calculator for your monthly expenses.

                    Part Two – Retirement Savings Simplified

                    Our recommendation is to utilize a Roth IRA and invest in index funds. This program __ from __
                    is a great Roth IRA program. Index funds represent investing in the market broadly and its growth
                    reflects the growth of the market. Index funds often outperform mutual funds and many of the offerings
                    401k managers invest retirement money into. Furthermore, 401k fee schedules erode the growth of
                    401k accounts. The fee schedules for index funds are usually 0.5% - 1%. 401k funds have fees 2%-3%.
                    The real wealth benefit of Roth IRAs is that withdrawals are not taxed, and the fund continues to
                    grow in the market even when you are in the withdrawal stage. Income from 401ks, traditional IRAs and
                    social security is taxed as income. Those invested well can balance their personal capital sources to keep
                    their money growing and their taxes minimized into their retirement years.
                </p>
              </div>
            </section>

            
    </div>
</template>
    <!--
        <h3>{{pageName}}</h3>
        <ul>
            <li>
                <h4>{{articleTitles[0]}}</h4>
                <p>
                    This site is here to help people gauge their own expensiveness, figure out how much you are going to cost yourself and then how much you're actually going to need. We want you to think about everything you need, want, or could have use of money for. 
                    The primary tool we've developed for this problem is the Capital Demand Forecaster. Adjust the session's user information to reflect your tax circumstances - state of residency, filing status, deductions, adjustments, and more to try to make as accurate a forecast as possible.
                    Enter the obligations, elections, and protections you want to plan for into the Capital Demand Forecaster's flexible expense table, set the costs and frequencies. Our algorithms go to work from there, totaling your costs and forecasting your capital demand along with a set of related ramification data

                    Our goal here is to get you a good idea of the wage you need to go after to satisify your capital demands, tax obligations, and savings elections.    

                    This forecast is an estimate based on the philosophy that everyone's bills have a gross sum total and that every gross income results in a particual net/ take home income. The program processes data in ways similar to other online budget takehome estimators. Its results are to be used with your own discretion and criticism. It is not making tax or investment advice. Its data returned is only as valuable as the quality of its input data. It is
                    
                    Warning - it is truly a budget calculator. Gross capital demands over $200,000 are likely to crash the program.  
                </p>
            </li>
            <li>
                <h4>{{articleTitles[1]}}</h4>
                <p>
                    Paying rent? Or have a mortgage? Its likely your housing bill is “always” due again. Avoid the
                    stress of a rent or mortgage crunch by setting aside the right portion of your paycheck whenever you are
                    paid. Commonly in the United States, housing is your largest bill and is due once per month. Paychecks
                    typically come weekly or biweekly. A strategy to reduce bill stress and always know you will be able to
                    pay for your roof is fractional amount saving. Divide your housing bill by your number of monthly
                    paychecks and move that amount into a savings account. When the bill comes due, you will have the
                    money ready and waiting.
                    This strategy can be ramped up how ever much you want. Insurance, car payment, and other
                    fixed rate periodic bills can be added to your housing cost and divided the same way to find the larger
                    number you need to save to cover your over all bills.
                    Reduce your recurring expenses as best you can. End subscriptions you don’t utilize, shop your
                    auto and life insurance policies and other bills when possible. Reduce the overall dollars demanded of
                    you, while still maintaining your obligations. Aim to make ends meet at first, then work on building
                    breathing space.
                </p>
            </li>
            <li>
                <h4>{{articleTitles[2]}}</h4>
                <p>
                    Self-protection is as natural an instinct as any. Financial self-protection is easy if you're sitting on a stack, but its also easy if you're not.
                    It is the first componenet of a solid financial base and can be obtained with the affordable, straightforward product discussed in the next paragraph. 
                    Knowing how much your monthly expenses and saving an emergency fund provides you a contingency plan and makes for base resilient. Getting to your demand wage will greatly help here. 

                    Life Insurance – Term life insurance. When you are young you often have high liabilities and low
                    cash wealth. Think education loans and mortgages. Life insurance provides protection to your family
                    from those liabilities. Term life insurance policies are the cheapest monthly policies and typically payout
                    hundreds of thousands of dollars, even millions sometimes. You can set your coverage. Offerings in the
                    range of $30 per month for $250,000 in coverage are common. Ladder is an excellent, economical, no
                    hassle life insurance company. Found out more.
                    The “downside” to term life insurance is that if you outlive your policy, then you receive
                    nothing. Term life insurance is truly a “just in case” product. The benefit is that for a low price you can
                    totally protect your assets, future obligations, and liabilities in the event of an untimely death. A cheaper
                    insurance policy leaves you with more capital for establishing an emergency fund and saving for
                    retirement (or saving for an old age supplemental fund if you don’t think you’ll ever retire).
                    In addition to traditional term life insurance, for those that would like a pay out if they outlive
                    their insurance term, there are return of premium life insurance. These policies cost more per month
                    compared to term life insurance for the same coverage, but they will return all the premiums you paid
                    over the years if you outlive the policy term, some even return a portion of interest accrued.
                    Avoid whole life insurance, it is overly expensive, it often underinsures and doesn’t payback till
                    the “whole” term has ended, often only when the insured person turns 100 years old. Yes you can
                    “cash” in the policy early, but based on how the payout value accrues, these are not very valuable till
                    the “term” is nearing its end (and again the “term” ends when the policy holder turns 100). Whole life
                    insurance is often marketed as a way to build a nest egg while protecting yourself as the policy must
                    payout, either when the policy holder dies or when they turn 100 years old. This is a bad strategy.
                    Insurance is for protection, not for growing your wealth or building your nest egg. In most cases if an
                    individual took the difference in premium costs between whole life insurance and term life insurance for
                    the same coverage, and invested in a Roth IRA comprised of index funds, they would end up wealthier.
                    
                    Emergency Fund – As recent world events have reinforced, it is very important to have money
                    saved for rainy days and unexpected cash flow interruptions. A common recommendation is to build up
                    an emergency fund and to set it aside somewhere you cannot spend it. The amount is in the end up to
                    you, but it is recommended to have a minimum of three months expenses saved. Reference the budget
                    calculator for your monthly expenses.

                    Part Two – Retirement Savings Simplified

                    Our recommendation is to utilize a Roth IRA and invest in index funds. This program __ from __
                    is a great Roth IRA program. Index funds represent investing in the market broadly and its growth
                    reflects the growth of the market. Index funds often outperform mutual funds and many of the offerings
                    401k managers invest retirement money into. Furthermore, 401k fee schedules erode the growth of
                    401k accounts. The fee schedules for index funds are usually 0.5% - 1%. 401k funds have fees 2%-3%.
                    The real wealth benefit of Roth IRAs is that withdrawals are not taxed, and the fund continues to
                    grow in the market even when you are in the withdrawal stage. Income from 401ks, traditional IRAs and
                    social security is taxed as income. Those invested well can balance their personal capital sources to keep
                    their money growing and their taxes minimized into their retirement years.
                </p>
            </li>
        </ul>
    -->
<script>
export default {
    name: "ResPage",
    data(){
        return {
            pageName: "Articles",
            articleTitles: ["What we're doing here. Why try making a budget invertedly?", "Pay the Roof Every Time You are Paid", "Solid Basis - Protection Now, Wealth Later", "The Shadow Costs of Living, Getting to Your Wage"]
        }
    }
}
</script>

<style>
    .container1{
        border: 1px solid white;
        border-radius: 5px;
        color: rgb(0, 0, 0);
        background-color: white;
        max-width: 70%
    }

    p{
        max-width: 175char;
        margin: 0 0 1em;
    }
    
        .accordion > input[type="checkbox"] {
      position: absolute;
      left: -100vw;
    }
    
    .accordion .content {
      overflow-y: hidden;
      height: 0;
      transition: height 0.3s ease;
    }
    
    .accordion > input[type="checkbox"]:checked ~ .content {
      height: auto;
      overflow: visible;
    }
    
    .accordion label {
      display: block;
    }
    
    /*
     Styling
    */
    body {
      font: 16px/1.5em "Overpass", "Open Sans", Helvetica, sans-serif;
      color: #333;
      font-weight: 300;
    }
    
    .accordion {
      margin-bottom: 1em;
    }
    
    .accordion > input[type="checkbox"]:checked ~ .content {
      padding: 15px;
      border: 1px solid #e8e8e8;
      border-top: 0;
    }
    
    .accordion .handle {
      margin: 0;
      font-size: 1.125em;
      line-height: 1.2em;
    }
    
    .accordion label {
      color: #333;
      cursor: pointer;
      font-weight: normal;
      padding: 15px;
      background: #e8e8e8;
    }
    
    .accordion label:hover,
    .accordion label:focus {
      background: #2a6124;
      color: rgb(255, 255, 255);
    }
    
    .accordion .handle label:before {
      font-family: 'fontawesome';
      content: none;
      display: inline-block;
      margin-right: 10px;
      font-size: .64em;
      line-height: 1.625em;
      vertical-align: middle;
    }
    
    .accordion > input[type="checkbox"]:checked ~ .handle label:before {
      content: none;
    }
    
    
    /*
     Demo purposes only
    
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
    
    body {
      padding: 40px;
    }
    
    a {
      color: #06c;
    }
    
    
    h1 {
      margin: 0 0 1.5em;
      font-weight: 600;
      font-size: 1.5em;
    }
    
    .accordion {
      max-width: 65em;
    }
    
    .accordion p:last-child {
      margin-bottom: 0;
    }
    */
    
    </style>

