import Vue from 'vue'
import App from './App.vue'
import Ads from 'vue-google-adsense'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-ZZRH17LP8W" }
});


Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
//import jsPDF from 'jspdf'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
