<template>

  <div id = "app">

    <div id = "gridHeader">  

    <TitleHeader :userInfoObj = userInfo @calcOn = "setEnabled($event)" @aboutOn = "setEnabled($event)" @helpOn = "setEnabled($event)" @articlesOn = "setEnabled($event)"/>      
    </div>

    <div id = "advert1">
      <p>
        By using this site and its data forecasters you agree to our terms of use. Click button below or go to about page for full terms.
      </p>
      <button @click = "setEnabled('aboutPage')" >Additional Information</button>
    </div>



    <div id = "gridContent">
      <CalcComp :userInfoObj = userInfo :class = "{disabled: !calcIsOn}"/>
      <ResPage v-if = "articlesIsOn == true" :class = "{disabled: !articlesIsOn}"/>
      <HelpPage v-if = "helpIsOn == true" :class = "{disabled: !helpIsOn}"/>
      <AboutPage v-if = "aboutIsOn == true" :class = "{disabled: !aboutIsOn}"/>
    </div>


    <div id = "advert2">
      <p>
        Capital Demand Forecasting: Forecast What You Need to Earn to Pay Your Bills and Afford Your Dreams.
      </p>
      <p>
        Data Privacy: Your data is not shared, saved or transferred offsite or to any third parties. This is a private service, free to use to the public.
      </p>
    </div>
    <div id = "gridFooter">
      <FooterNav/> <!-- @calcOn = "setEnabled($event)" @resOn = "setEnabled($event)"   viewContent : true-->
    </div>   
  </div>
</template>

<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5515755819474383"
     crossorigin="anonymous"></script>

<script>
import TitleHeader from './components/TitleHeader.vue'
import CalcComp from './components/Calculators.vue'
import ResPage from './components/ResourcesPage.vue'
import HelpPage from './components/HelpPage.vue'
import AboutPage from './components/AboutPage.vue'
import FooterNav from './components/FooterNav.vue'

export default {
  name: 'App',
  data(){
    return{
      //selector: "calcPage",
      calcIsOn: true,
      articlesIsOn: false,
      aboutIsOn: false,
      helpIsOn: false,
          }
        },
  components: {
    TitleHeader, CalcComp, ResPage, HelpPage, AboutPage, FooterNav
  },
  //mounted:function(){this.$root.$on('CalcComp', () => { this.initializeDataTable(), this.summedCost()})},  @editUiOn = "setEnabled($event)"
  methods: {
    contentSelector(x){ 
      this.selector = x;
    },
    setUIObject(x){ 
      this.userInfo = x
      console.log(this.userInfo);
      console.log(this.userInfo.locality);
    },
    setEnabled(x){
      console.log(`setting ${x} to true`)
      switch (x){
        case "calcPage":
          this.calcIsOn = true;
          this.articlesIsOn = false;
          this.aboutIsOn = false;
          this.helpIsOn = false;
          console.log(`Calculator is On`)
          break;
        case "aboutPage":
          this.calcIsOn = false;
          this.articlesIsOn = false;
          this.aboutIsOn = true;
          this.helpIsOn = false;
          console.log(`About is On`)
          break;
        case "helpPage": 
          this.calcIsOn = false;
          this.articlesIsOn = false;
          this.aboutIsOn = false;
          this.helpIsOn = true;
          console.log(`Help is On`)
          break;
        case "artPage":
          this.calcIsOn = false;
          this.articlesIsOn = true;
          this.aboutIsOn = false;
          this.helpIsOn = false;
          console.log(`Resources is On`)
          break;
      }
      }
    },
  computed:{ 
    //retSelector(){return this.selector;}
  },
}

</script>

<style>


@media all and (max-width: 800px){
  body{
    background: #fdfff6;
  }
  #advert1, #advert2{
    display: block;
    height: 100px;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    text-transform: uppercase;
    background: rgb(255, 255, 255);
    margin-bottom: 8px;
    border: 1px solid rgb(0, 0, 0);
    overflow: auto;
  }
  #app {
    grid-template-areas: 
      "hm"
      "ad1m"
      "cm"
      "ad2m"
      "fm";
    grid-template-rows: 48px 30px minmax(1fr auto) 30px 35px;
    grid-template-columns: 1fr;
  }

  #gridFooter {
    grid-area: fm;
  } 

  #advert1{
    grid-area: ad1m;
    overflow: auto;
  }

  #advert1 > p{
    width: fit-content;
    font-size: 12px;
  }

  #advert2{
    grid-area: ad2m; 
    overflow: auto;
    font-size: 12px;
  }

  #advert2 > p{
    width: fit-content;
    font-size: 12px;
  }

  #gridContent{
    width: 100%;
    grid-area: cm;
    border-radius: 5px;
    overflow: hidden;
  }

  #gridHeader {
    grid-area: hm;
  }

.navDirects{
  z-index: 9999;
  margin: 8px;
  padding: 0 8px;
  max-width: calc(100% - 16px);
  box-sizing: border-box;
}
.navBar{
  margin-bottom: 8px;
  display: block;
}
.sessInfoContainer{
  max-width: 100%;
}
.containerApp{
  display: block;
}
#app{
  overflow-x: hidden;
}

.sessInfoTable{
  transform: scale(0.75);
  width: 374px;
  margin: 0 auto;
}
.sessInfoContainer .sessInfoTable{
  display: flex;
  flex-direction: row;
}
.sessInfoContainer .sessInfoTable tr{
  flex-direction: column;
  display: flex;
}
.sessInfoContainer .sessInfoTable th, .sessInfoContainer .sessInfoTable td{
  text-align: left !important;
  align-items: left;
  display: flex;
}
.sessInfoContainer .sessInfoTable > tr > td > select {
    padding: 0 !important;
    width: 100%;
}
.sessInfoContainer .sessInfoTable > tr > th{
  margin: 10px 0;
}
.sessInfoContainer .sessInfoTable > tr > td{
  margin: 0;
}

#userInfoTable{
  margin-top: -45px;
}
#userInfoTable, #userInfoTable tr{
  display: block;
}
#userInfoTable > tr > td > select, #userInfoTable > tr > td > input {
    width: 100%;
}

#forecastCalc{
  margin: 0 auto !important;
  width: 370px;
  max-width: 100%
}
#forecastData{
  padding: 0;
}
#expTable{
  width: 374px;
  transform: scale(0.7);
}
#expTable th, #expTable td{
  overflow-x: hidden;
  font-size: 10px !important;
}
#expTable select, #expTable input{
  font-size: 10px !important;
  width: 80%;
  padding: 1px;
}
#expTable svg{
  width: 15px;
  height: 15px;
}
#expTable th:nth-child(4), #expTable th:nth-child(2), #expTable th:nth-child(3), #expTable td:nth-child(4), #expTable td:nth-child(2), #expTable td:nth-child(3){
  display: none;
}

#forecastData td:nth-child(1){
    width: 80px;

}
#lastTable td:nth-child(1){
  width: 100px;
  text-align: left;
}
#capitalDemandContainer {
    display: block;
}
#fedtax, #expTable{
  table-layout: unset;
}
#fedtax td:nth-child(1), .bracketNo{
  width: 20px !important;
}

#expTable th:nth-child(6), #expTable td:nth-child(6){
  width: 45px;
}

#expTable{
  width:340px;
}
#expTable th:nth-child(11), #expTable td:nth-child(11){
  padding-right: 30px;
}

}


@media all and (min-width: 801px) and (max-width: 999px){
  #gridHeader{
    height: 85px;
  }
  #app > div{
    width: calc(100vw - 16px);
    box-sizing: border-box;
  }
  #app{
    grid-template-areas:
        "h"
        "ad1"
        "c"
        "ad2"
        "f" !important;
    grid-template-rows: 85px 100px 1fr 100px 35px !important;
    grid-template-columns: 1fr !important;
  }
  .navBar{
    display: block;
  }
  #forecastCalc{
    margin: 0 auto !important;
  }
  .sessInfoTable, #expTable {
    transform: scale(0.9);
    margin: 0 auto;
  }
}


@media all and (min-width: 801px){

html{
  width: 100%;
  height: 96%;
}

body{
  overflow: hidden;
  background: white;
}

#app {
  margin: 0 auto;
  max-height: 100vh;
  min-height: 100vh;
  max-width: 96vw;
  padding-right: 1%;
  display: grid;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  grid-template-rows: 48px 1fr 55px;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-areas: 
    "h h h"
    "ad1 c ad2"
    "f f f";
  }

  #gridHeader {
    grid-area: h;
    max-height: auto;
  }

  #gridContent {
    grid-area: c;
    margin: 0 auto;
    overflow-x: disabled;
    overflow-y: auto;
    max-height: 90vh;
    width: auto;
    max-width: 65vw;
    width: 60vw;

  }

  #gridFooter {
    grid-area: f;
    padding-bottom: 15px;
  } 

  #advert1{
    grid-area: ad1;
    border-radius: 5px;
    background: white;
    padding: 1.2em;
    overflow: auto;
    width: 14vw;
  }

  p {
    max-width: fit-content;
    overflow-y: auto;
  }

  #advert2{
    grid-area: ad2;
    border-radius: 5px;
    background: white;
    padding: 1.2em;
    overflow: auto;
  }

  .disabled {
  visibility: hidden;
  height: 0px;
}
}


/*

<div id = "gridContent" v-if = "calcIsOn == true">
      <CalcComp :userInfoObj = userInfo :class = "{disabled: !calcIsOn}"/>
    </div>
    <div id = "gridContent" v-else-if = "articlesIsOn == true">
      <ResPage :class = "{disabled: !articlesIsOn}"/>
    </div>
    <div id = "gridContent" v-else-if = "helpIsOn == true">
      <HelpPage :class = "{disabled: !helpIsOn}"/>
    </div>
    <div id = "gridContent" v-else-if = "aboutIsOn == true">
      <AboutPage :class = "{disabled: !aboutIsOn}"/>
    </div>
    
    <div id = "gridContent" v-else>
      Error page
    </div>

@media all and (max-width: 800px){
  #app {
    grid-template-areas: 
      "hm"
      "ad1m"
      "cm"
      "ad2m"
      "fm";
    grid-template-rows: 48px 30px minmax(1fr auto) 30px 35px;
    grid-template-columns: 1fr;
  }

  #gridFooter {
    grid-area: fm;
  } 

  #advert1{
    grid-area: ad1m;
  }

  #advert2{
    grid-area: ad2m; 
  }

  #gridContent{
    width: 95vw;
    grid-area: cm;
  }

  #gridHeader {
    grid-area: hm;
  }
}

@media all and (min-width: 801px){

html{
  width: 100%;
  height: 96%;
}

body{
  overflow: hidden;
  background: rgb(0, 0, 0); 
}

#app {
  margin: 0 auto;
  max-height: 100vh;
  min-height: 100vh;
  display: grid;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  grid-template-columns: 11% 78% 11%; 
  grid-template-rows: 48px 1fr 65px; 
  grid-template-areas: 
    "h h h"
    "ad1 c ad2"
    "f f f";
  }

  #gridHeader {
    grid-area: h;
    max-height: auto;
  }

  #gridContent {
    grid-area: c;
    margin: 0 auto;
    overflow-x: disabled;
    overflow-y: auto;
    max-height: 90vh;
    width: auto;
    max-width: 96vw;

  }

  #gridFooter {
    grid-area: f;
  } 

  #advert1{
    grid-area: ad1;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 5px;
    text-transform: uppercase;
    background: rgb(255,248,138);
    background: linear-gradient(111deg, rgba(255,248,138,1) 3%, rgba(241,147,38,1) 32%, rgba(249,249,249,1) 85%);
  }
  #advert2{
    grid-area: ad2;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 5px;
    text-transform: uppercase;
    background: rgb(255,248,138);
    background: linear-gradient(111deg, rgba(255,248,138,1) 3%, rgba(241,147,38,1) 32%, rgba(249,249,249,1) 85%);
  }

  .disabled {
  visibility: hidden;
  height: 0px;
}
}
*/



</style>
